import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageDetector: LanguageDetectorAsyncModule = {
  // TODO: handle language detection in next versions of IGA
  type: 'languageDetector',
  async: true,
  detect: callback => callback('en'),
  // tslint:disable-next-line:no-empty
  init: () => {},
  // tslint:disable-next-line:no-empty
  cacheUserLanguage: () => {},
};

const resources = {
  en: {
    common: require('./en/common.json'),
    campaigns: require('./en/campaigns.json'),
    items: require('./en/items.json'),
    orders: require('./en/orders.json'),
    messages: require('./en/message.json'),
    shipments: require('./en/shipments.json'),
    payments: require('./en/payments.json'),
    users: require('./en/users.json'),
    maintenance: require('./en/maintenance.json'),
    manageContent: require('./en/manage-content.json'),
    account: require('./en/account.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    returnNull: false,
    resources,
    debug: false,
    joinArrays: '',
    fallbackLng: 'en',
    ns: [
      'common',
      'campaigns',
      'items',
      'orders',
      'messages',
      'shipments',
      'payments',
      'users',
      'maintenance',
      'manageContent',
      'account',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
