import { AxiosPromise } from 'axios';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { ItemInfoDto } from '../../services/Item/itemService.dto';
import { usePopup } from '../../providers/PopupProvider';
import { itemService } from '../../services/Item/itemService';
import { campaignService } from '../../services/Campaign/campaignService';
import { CampaignAdminDto } from '../../services/Campaign/campaignService.dto';
import UpdateSortingBoostForm from './UpdateSortingBoostForm';

export const useUpdateSortingBoost = (onUpdated?: () => void) => {
  const { showPopover, hidePopover } = usePopup();
  const { handleError } = useGlobalError();

  const displayPopover = (
    anchor: HTMLDivElement,
    value: number,
    serviceCall: (value: number) => AxiosPromise<void>
  ) => {
    const handleConfirm = (value: number) => {
      serviceCall(value)
        .then(() => {
          hidePopover();
          onUpdated?.();
        })
        .catch(error => handleError(error));
    };
    showPopover(<UpdateSortingBoostForm value={value} onConfirmPress={handleConfirm} />, anchor);
  };

  const handleItemSortingBoostUpdate = (anchor: HTMLDivElement, data: ItemInfoDto) => {
    displayPopover(anchor, data.adminSortingBoost, value => itemService.updateSortingBoost(data.id, value));
  };

  const handleCampaignSortingBoostUpdate = (anchor: HTMLDivElement, data: CampaignAdminDto) => {
    displayPopover(anchor, data.adminSortingBoost, value => campaignService.updateSortingBoost(data.id, value));
  };

  return { handleItemSortingBoostUpdate, handleCampaignSortingBoostUpdate };
};
