import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { theme } from '../../../../assets/styles/theme';
import { MessageDto } from '../../../../services/Message/messageService.dto';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';

const MsgImageSmall = styled.img`
  border-radius: 12px;
  max-width: 50%;
  cursor: pointer;
`;

const MsgImageLarge = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 12px;
  background-color: ${theme.color.white};
  outline: none;
`;

const ImgModal = styled(Modal)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: ${theme.zIndex.modal};
`;

interface Props {
  message: MessageDto;
}

const MessageImage = (props: Props) => {
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const imgUrl = props.message.mediaUrl;
  const isImgExpired =
    props.message.mediaUrlExpirationTime &&
    new Date().valueOf() > new Date(props.message.mediaUrlExpirationTime).valueOf();

  const toggleModal = () => {
    setIsImgModalOpen(!isImgModalOpen);
  };

  return (
    <>
      {isImgExpired && <LoadingIndicator />}
      {!isImgExpired && <MsgImageSmall src={imgUrl} alt={'msg-img'} onClick={toggleModal} />}
      <ImgModal open={isImgModalOpen} onClose={toggleModal}>
        <MsgImageLarge src={imgUrl} alt={'msg-img'} />
      </ImgModal>
    </>
  );
};

export default MessageImage;
