import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../assets/styles/theme';
import { CampaignAdminDto } from '../../../../services/Campaign/campaignService.dto';
import CampaignsListItem from './CampaignsListItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35px;
  max-height: 250px;
  background-color: ${theme.color.white};
  border-radius: 10px;
  border: 2px solid ${theme.color.lighterGray};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0;
  z-index: 1;
`;

interface Props {
  campaigns: CampaignAdminDto[];
  onCampaignSelected: (campaign: CampaignAdminDto) => void;
}

const CampaignsList = ({ campaigns, onCampaignSelected }: Props) => {
  if (!campaigns || campaigns.length === 0) return <></>;
  return (
    <Container>
      {campaigns.map(campaign => (
        <CampaignsListItem key={campaign.id} campaign={campaign} onClick={() => onCampaignSelected(campaign)} />
      ))}
    </Container>
  );
};

export default CampaignsList;
