import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import { Column, TableFilterStateDto } from '../../components/table/DataTable';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import { TableFilter } from '../../components/table/FiltersPanel';
import { DataTableRef } from '../../components/table/dataTableRef';
import { orderService } from '../../services/Order/orderService';
import {
  REFUND_REQUEST_STATUSES,
  RefundRequestDto,
  RefundRequestUpdateDto,
} from '../../services/Order/orderService.dto';
import { dateUtils } from '../../utils/dateUtils';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import CellWithNavigate from '../../components/table/CellWithNavigate';
import RefundRequestActions from './components/RefundRequestActions';
import { convertFiltersToRefundRequestFilterDto } from './utils/filterConverter';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

type UrlParams = {
  orderId: string;
};

const RefundRequests = () => {
  const { t } = useTranslation('orders');
  const dataTableRef = useRef<DataTableRef>(null);
  const { handleError } = useGlobalError();
  const { orderId } = useParams<UrlParams>();

  const columns: Column<RefundRequestDto>[] = [
    {
      id: 'id',
      title: t('columns.id'),
      isSortable: true,
      maxWidth: 20,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'orderId',
      title: t('columns.order-id'),
      isSortable: true,
      maxWidth: 20,
      align: 'center',
      renderCell: data => (
        <CellWithNavigate url={`/orders/list/${data.orderId}`} title={t('go-to-orders-tooltip', { id: data.orderId })}>
          {data.orderId}
        </CellWithNavigate>
      ),
    },
    {
      id: 'refundReason',
      title: t('columns.refund-reason'),
      isSortable: false,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.refundReason,
    },
    {
      id: 'status',
      title: t('columns.status'),
      isSortable: true,
      maxWidth: 60,
      align: 'center',
      renderCell: data => data.status,
    },
    {
      id: 'notes',
      title: t('columns.notes'),
      isSortable: false,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.notes,
    },
    {
      id: 'updatedBy',
      title: t('columns.updated-by'),
      isSortable: true,
      maxWidth: 20,
      align: 'center',
      renderCell: data => data.updatedBy,
    },
    {
      id: 'created',
      title: t('columns.created'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 60,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.creationDate)),
    },
    {
      id: 'updated',
      title: t('columns.updated'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 60,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.updateDate)),
    },
    {
      id: 'actions',
      title: t('columns.actions'),
      align: 'center',
      maxWidth: 30,
      renderCell: data => <RefundRequestActions refundRequest={data} onEditRefundRequest={handleRefundRequestEdit} />,
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'orderId', label: t('filters.order-id') },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...REFUND_REQUEST_STATUSES],
      label: t('filters.status'),
    },
  ];

  const handleRefundRequestEdit = (id: number, data: RefundRequestUpdateDto) => {
    orderService
      .updateRefundRequest(id, data)
      .then(() => dataTableRef.current?.refresh())
      .catch(error => handleError(error));
  };

  const handleTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filters = convertFiltersToRefundRequestFilterDto(tableState);
    return orderService.fetchRefundRequests(filters).then(response => response.data);
  }, []);

  const filterInitialState = useMemo(() => {
    const orderIdFromPath = orderId ? parseInt(orderId) : undefined;
    if (orderIdFromPath && Number.isInteger(orderIdFromPath)) {
      return new Map([['orderId', orderIdFromPath]]);
    } else {
      return new Map();
    }
  }, [orderId]);

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          filterInitState={filterInitialState}
          onTableStateChanged={handleTableStateChange}
        />
      </Container>
    </PageContainer>
  );
};

export default RefundRequests;
