import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloseButton, ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader, FormText } from '../../../components/typography/PopoverTypography';
import closeIcon from '../../../assets/svg/close.svg';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { ActionType, buttonNameMap, warningMessageMap } from '../utils/dictionary';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 400px;
`;

interface Props {
  anchor: SVGSVGElement | HTMLDivElement | null;
  objectId: number;
  objectType: string;
  action: ActionType;
  onActionPressed: (comment?: string) => void;
  onClosePressed: () => void;
}

const ReportActionPopover = (props: Props) => {
  const { t } = useTranslation('manageContent');
  const [comment, setComment] = useState('');

  const handleActionPress = () => {
    props.onActionPressed(comment);
  };

  const buttonName = buttonNameMap.get(props.action);
  const warningText = () => {
    const warning = warningMessageMap.get(props.action);
    return warning ? t(warning, { type: props.objectType, id: props.objectId }) : '';
  };

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        {props.action !== ActionType.COMMENT && (
          <FormHeader>
            <b>{t('unsafe-content.popover.header-label')}</b>
          </FormHeader>
        )}
        <FormText>{warningText()}</FormText>
        <CloseButton src={closeIcon} alt={'close'} onClick={props.onClosePressed} />
        <InputWithLabel
          label={t('unsafe-content.popover.comment-label')}
          onChange={setComment}
          value={comment}
          multiline={true}
          minWidth={300}
        />
        {buttonName && <ConfirmButton label={t(buttonName)} onClick={handleActionPress} />}
      </FormContainer>
    </Popover>
  );
};

export default ReportActionPopover;
