import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextMain } from '../typography/Texts';
import RectangularButton from '../buttons/RectangularButton';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  padding: 10px;
  max-width: 400px;
`;

const ButtonYes = styled(RectangularButton)`
  font-weight: normal;
  min-width: 140px;
  height: 40px;
`;

const ButtonNo = styled(ButtonYes)`
  background-color: ${theme.color.white};
  border: 2px solid ${theme.color.red};
  color: ${theme.color.red};
`;

const Message = styled(TextMain)`
  white-space: pre-wrap;
  margin: 15px 5px;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

interface Props {
  msg: string;
  onYesClick: () => void;
  onNoClick: () => void;
}

const ConfirmDialog = ({ msg, onYesClick, onNoClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={onNoClick}>
      <Container>
        <Message>{msg}</Message>
        <ButtonsContainer>
          <ButtonYes label={t('yes')} onClick={onYesClick} />
          <ButtonNo label={t('no')} onClick={onNoClick} />
        </ButtonsContainer>
      </Container>
    </Dialog>
  );
};

export default ConfirmDialog;
