import React from 'react';
import { useTranslation } from 'react-i18next';
import Auctions from './Auctions';

const UnpaidAuctions = () => {
  const { t } = useTranslation('items');
  return <Auctions title={t('auctions.title-unpaid')} auctionStatuses={['AWAITING_PAYMENT', 'ONGOING']} />;
};

export default UnpaidAuctions;
