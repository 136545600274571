import React from 'react';
import { ReportDto } from '../../../services/Report/reportService.dto';
import CellWithPopover from '../../../components/table/CellWithPopover';
import { ObjectType } from '../utils/dictionary';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import CampaignDetailsCard from '../../campaigns/components/CampaignDetailsCard';
import ItemDetailsCard from '../../items/components/ItemDetailsCard';
import CommentDetailsCard from '../../campaigns/components/CommentDetailsCard';

interface Props {
  reportDto: ReportDto;
}

const ObjectIdCell = ({ reportDto }: Props) => {
  const renderPopover = (type: string, id: number) => {
    if (type === ObjectType.USER) return <UserDetailsCard userId={id} />;
    else if (type === ObjectType.CAMPAIGN) return <CampaignDetailsCard campaignId={id} />;
    else if (type === ObjectType.ITEM) return <ItemDetailsCard itemId={id} />;
    else if (type === ObjectType.COMMENT) return <CommentDetailsCard commentId={id} />;
  };

  return (
    <CellWithPopover popover={renderPopover(reportDto.objectType, reportDto.objectId)}>
      {reportDto.objectId}
    </CellWithPopover>
  );
};

export default ObjectIdCell;
