import styled from 'styled-components';
import { theme } from '../../../../assets/styles/theme';
import { SectionHeader } from '../../../../components/typography/Headers';

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FormHeader = styled(SectionHeader)`
  text-align: center;
  margin: 10px;
`;

export const FormTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  border: 2px solid ${theme.color.lighterGray};
  overflow-y: auto;

  & tr {
    border-bottom: 2px solid ${theme.color.lighterGray};
    height: 55px;
  }
  & tr:last-child {
    border-bottom: none;
  }
  & td {
    padding: 0 10px;
  }
  & td:last-child {
    width: 80%;
    border-left: 2px solid ${theme.color.lighterGray};
  }
`;
