import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import { theme } from '../../assets/styles/theme';
import { TextMain } from '../typography/Texts';

const Container = styled.div<{ alignCenter?: boolean }>`
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.alignCenter ? 'center' : 'flex-start')};
`;

const Label = styled(TextMain)`
  color: ${theme.color.darkerGray};

  &:hover {
    color: ${theme.color.black};
  }
`;

interface Props {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  alignCenter?: boolean;
}

const CheckboxInputWithLabel = (props: Props) => {
  return (
    <Container alignCenter={props.alignCenter}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={checkbox => props.onChange(checkbox.target.checked)}
            style={{ color: theme.color.red, transform: 'scale(1.5)' }}
          />
        }
        label={<Label>{props.label}</Label>}
      />
    </Container>
  );
};

export default CheckboxInputWithLabel;
