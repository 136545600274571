import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import CampaignSelector from '../../../campaigns/components/campaignSelector/CampaignSelector';
import FormRow from './FormRow';

const CampaignInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setCampaign } = useItemForm();
  return (
    <FormRow label={t('add-item.campaign')}>
      <CampaignSelector campaign={formData.campaign} onCampaignSelected={setCampaign} />
    </FormRow>
  );
};

export default CampaignInputRow;
