import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import { SectionHeader, SubTitle } from '../typography/Headers';
import { TextSmall } from '../typography/Texts';

export const ImageContainer = styled.div`
  width: 250px;
  height: 100%;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
`;

export const LargeImage = styled.img`
  width: 100%;
  border-radius: 12px;
  border: 2px dotted ${theme.color.darkerGray};
  display: flex;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const MiniImageContainer = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  gap: 5px;
  overflow-x: auto;
`;

export const MiniImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: 2px dotted ${theme.color.darkerGray};
  cursor: pointer;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const CardContainer = styled.div`
  width: 700px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

export const CardHeader = styled(SectionHeader)`
  border-bottom: 2px solid ${theme.color.darkerGray};
  color: ${theme.color.darkerGray};
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 10px 0;
  gap: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 10px;
  column-gap: 20px;
`;

export const CardTitle = styled(SubTitle)<{ isHidden?: boolean }>`
  color: ${props => (props.isHidden ? theme.color.darkerGray : theme.color.red)};
  text-decoration: ${props => (props.isHidden ? 'line-through' : 'auto')};
`;

export const CardCreatedInfo = styled(TextSmall)`
  color: ${theme.color.darkerGray};
`;
