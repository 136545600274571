import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  EditItemDto,
  ItemCategoryDto,
  ItemConditionDto,
  ItemFilterDto,
  ItemInfoDto,
  ItemPublicDto,
  NewItemDto,
} from './itemService.dto';

class ItemService {
  static BASE_URL = `${apiUrl}/api/item`;

  fetchPublicItems(ids: number[]): AxiosPromise<ItemPublicDto[]> {
    let url = `${ItemService.BASE_URL}/public/items/bulk`;
    if (ids.length > 0) {
      const idQuery = ids.map(it => `ids=${it}`).join('&');
      url += `?${idQuery}`;
    }
    return apiService.get(url);
  }

  fetchItems(filter: ItemFilterDto): AxiosPromise<PageableType<ItemInfoDto>> {
    const url = `${ItemService.BASE_URL}/admin/items`;
    return apiService.get(url, filter);
  }

  fetchSingleItem(id: number): Promise<ItemInfoDto> {
    const url = `${ItemService.BASE_URL}/admin/items`;
    return apiService.get(url, { page: 0, size: 1, id: id }).then(response => {
      return response?.data?.content[0];
    });
  }

  updateSortingBoost(itemId: number, adminSortingBoost: number): AxiosPromise<void> {
    const url = `${ItemService.BASE_URL}/admin/items/${itemId}/admin-sorting-boost`;
    return apiService.patch(url, {}, {}, { adminSortingBoost });
  }

  fetchConditions(): AxiosPromise<ItemConditionDto[]> {
    const url = `${ItemService.BASE_URL}/public/conditions`;
    return apiService.get(url);
  }

  fetchCategories(): AxiosPromise<ItemCategoryDto[]> {
    const url = `${ItemService.BASE_URL}/public/categories`;
    return apiService.get(url);
  }

  addItemByEmployee(itemDetails: NewItemDto, userId: number): AxiosPromise<ItemPublicDto> {
    const url = `${ItemService.BASE_URL}/admin/user/${userId}/items`;
    return apiService.post(url, {}, {}, itemDetails);
  }

  editItemByEmployee(itemDetails: EditItemDto): AxiosPromise<ItemPublicDto> {
    const url = `${ItemService.BASE_URL}/admin/items`;
    return apiService.put(url, {}, {}, itemDetails);
  }
}

const itemService = new ItemService();

export { itemService, ItemService };
