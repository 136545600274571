import React, { createContext, FC, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../components/dialogs/ErrorDialog';
import { useAuth } from './AuthProvider';

interface GlobalErrorContextType {
  handleError: (error: AxiosError, message?: string, map?: Map<number, string>) => void;
  errorMessage: (message: string) => void;
}

const GlobalErrorContext = createContext<GlobalErrorContextType>(null!);

const GlobalErrorProvider: FC<{ children: React.ReactNode }> = props => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');
  const [isLogout, setLogout] = useState<boolean>(false);
  const { logout } = useAuth();

  const handleError = (e: AxiosError, message?: string, map?: Map<number, string>) => {
    setError(processAxiosError(e, message, map));
  };

  const errorMessage = (message: string) => {
    setError(message);
  };

  const onClose = () => {
    if (isLogout) {
      logout();
      setLogout(false);
    }
    setError('');
  };

  const processAxiosError = (axiosError: AxiosError, message?: string, map?: Map<number, string>): string => {
    const errorStatus = axiosError.response?.status;

    if (errorStatus === 401) {
      setLogout(true);
      return t('error.unauthenticated');
    }

    if (errorStatus && map) {
      return map.get(errorStatus) || message || t('error.generic');
    }

    return message || t('error.generic');
  };

  return (
    <GlobalErrorContext.Provider value={{ handleError, errorMessage }}>
      {props.children}
      <ErrorDialog errorMsg={error} onClose={onClose} />
    </GlobalErrorContext.Provider>
  );
};

const useGlobalError = () => useContext(GlobalErrorContext);

export { GlobalErrorProvider, useGlobalError };
