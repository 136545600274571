import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../../components/inputs/SearchInput';
import { CampaignAdminDto, CampaignFilterDto } from '../../../../services/Campaign/campaignService.dto';
import { campaignService } from '../../../../services/Campaign/campaignService';
import { theme } from '../../../../assets/styles/theme';
import { TextMain } from '../../../../components/typography/Texts';
import { isNumeric } from '../../../../utils/textUtils';
import CampaignsList from './CampaignsList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: normal;
  margin: 0 5px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled(ClearIcon)`
  cursor: pointer;
  color: ${theme.color.red};
  margin: 0 10px;
`;

interface Props {
  campaign?: CampaignAdminDto;
  onCampaignSelected: (campaign?: CampaignAdminDto) => void;
}

const CampaignSelector = ({ campaign, onCampaignSelected }: Props) => {
  const { t } = useTranslation('campaigns');
  const [searchValue, setSearchValue] = useState('');
  const [campaigns, setCampaigns] = useState<CampaignAdminDto[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignAdminDto | undefined>(campaign);

  const handleCampaignSelected = (campaign?: CampaignAdminDto) => {
    setSelectedCampaign(campaign);
    onCampaignSelected(campaign);
  };

  useEffect(() => {
    if (!searchValue) {
      setCampaigns([]);
    } else {
      const filter: CampaignFilterDto = {
        page: 0,
        size: 10,
        sort: 'created,desc',
        name: isNumeric(searchValue) ? undefined : searchValue,
        id: isNumeric(searchValue) ? Number.parseInt(searchValue) : undefined,
      };
      campaignService
        .fetchCampaigns(filter)
        .then(response => setCampaigns(response.data.content?.filter(campaign => campaign.status === 'ACTIVE') || []));
    }
  }, [searchValue]);

  useEffect(() => setSelectedCampaign(campaign), [campaign]);

  if (selectedCampaign) {
    return (
      <Container>
        <SelectedContainer>
          <TextMain>{selectedCampaign ? `${selectedCampaign.name} (#${selectedCampaign.id})` : ''}</TextMain>
          <Icon style={{ fontSize: '16px' }} onClick={() => handleCampaignSelected(undefined)} />
        </SelectedContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <SearchInput onChange={setSearchValue} value={searchValue} placeholder={t('search-placeholder')} />
        <CampaignsList campaigns={campaigns} onCampaignSelected={handleCampaignSelected} />
      </Container>
    );
  }
};

export default CampaignSelector;
