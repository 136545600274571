import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import { MessagesProvider, useMessages } from '../../providers/messages/MessagesProvider';
import NavMenuItem from '../../components/navbar/NavMenuItem';
import PageContainer from '../../components/containers/PageContainer';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import SearchInput from '../../components/inputs/SearchInput';
import {
  MESSAGES_TAB_ONGOING_PATH,
  MESSAGES_TAB_QUEUE_PATH,
  MESSAGES_TAB_SUPPORTED_PATH,
} from '../../providers/messages/messagesPaths';
import ConversationTabQueue from './components/tabs/ConversationTabQueue';
import ConversationTabOngoing from './components/tabs/ConversationTabOngoing';
import ConversationTabSupported from './components/tabs/ConversationTabSupported';
import ActionsList from './components/actions/ActionsList';
import MessageThread from './components/thread/MessageThread';
import SearchTypePicker from './components/SearchTypePicker';
import { SearchType } from './components/searchType';

const Container = styled.div`
  flex-direction: row;
  padding: 0 ${theme.paddingStandard}px;
  display: flex;
  flex: 1;
`;

const ListSection = styled.div<{ isConversationSelected: boolean }>`
  display: ${props => (props.isConversationSelected ? 'none' : 'flex')};
  position: relative;
  flex: 1;
  flex-direction: column;
  padding-top: 10px;
  border-right: none;
  padding-right: 0;

  ${theme.mq.phone} {
    display: flex;
    border-right: ${theme.color.lightGray} 2px solid;
    padding-right: 5px;
  }
`;

const ThreadSection = styled.div<{ isConversationSelected: boolean }>`
  display: ${props => (props.isConversationSelected ? 'flex' : 'none')};
  flex: 2;
  padding-left: 0;
  padding-right: 5px;

  ${theme.mq.phone} {
    display: flex;
    padding-left: ${theme.paddingStandard}px;
    padding-right: 0;
  }

  ${theme.mq.desktop} {
    display: flex;
    padding-right: ${theme.paddingStandard}px;
  }
`;

const ActionSection = styled.div`
  display: none;
  position: relative;
  flex: 1;
  flex-direction: column;
  border-left: none;

  ${theme.mq.desktop} {
    display: flex;
    border-left: ${theme.color.lightGray} 2px solid;
  }
`;

const TabSelector = styled.div`
  margin-right: ${theme.paddingStandard}px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

type UrlParams = {
  conversationId: string;
  tabName: string;
};

interface MessageTab {
  name: string;
  path: string;
}

const Messages = () => {
  const { t } = useTranslation('messages');

  const TAB_QUEUE = { name: t('tab-queue'), path: MESSAGES_TAB_QUEUE_PATH };
  const TAB_ONGOING = { name: t('tab-ongoing'), path: MESSAGES_TAB_ONGOING_PATH };
  const TAB_SUPPORTED = { name: t('tab-supported'), path: MESSAGES_TAB_SUPPORTED_PATH };
  const MESSAGE_TABS: MessageTab[] = [TAB_QUEUE, TAB_ONGOING, TAB_SUPPORTED];
  const DEFAULT_TAB = TAB_QUEUE;

  const [selectedConversationId, setSelectedConversationId] = useState<number | undefined>();
  const location = useLocation();
  const navigate = useNavigate();
  const { tabName, conversationId } = useParams<UrlParams>();
  const { selectConversation } = useMessages();
  const { supporters } = useGlobalData();
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState<SearchType>('Content');

  useEffect(() => {
    const conversationIdFromUrl = parseInt(conversationId!) || undefined;
    if (!tabName) navigate(DEFAULT_TAB.path);
    else setSelectedConversationId(conversationIdFromUrl);
    if (!conversationIdFromUrl) selectConversation(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName, conversationId]);

  const getSelectedTab = () => {
    return MESSAGE_TABS.filter(item => location.pathname.includes(item.path))[0];
  };

  const handleCloseConversation = () => {
    const selectedTab = getSelectedTab();
    selectConversation(undefined);
    navigate(selectedTab.path);
  };

  const renderConversationList = () => {
    const selectedTab = getSelectedTab();
    if (selectedTab === TAB_QUEUE) {
      return (
        <ConversationTabQueue
          path={selectedTab.path}
          selectedConversationId={selectedConversationId}
          onCloseConversation={handleCloseConversation}
          searchValue={searchValue}
          searchType={searchType}
        />
      );
    } else if (selectedTab === TAB_ONGOING) {
      return (
        <ConversationTabOngoing
          path={selectedTab.path}
          selectedConversationId={selectedConversationId}
          onCloseConversation={handleCloseConversation}
          searchValue={searchValue}
          searchType={searchType}
        />
      );
    } else if (selectedTab === TAB_SUPPORTED) {
      return (
        <ConversationTabSupported
          path={selectedTab.path}
          selectedConversationId={selectedConversationId}
          onCloseConversation={handleCloseConversation}
          searchValue={searchValue}
          searchType={searchType}
        />
      );
    }
  };

  if (supporters.length === 0)
    return (
      <PageContainer>
        <LoadingIndicator />
      </PageContainer>
    );
  return (
    <PageContainer>
      <Container>
        <ListSection isConversationSelected={selectedConversationId !== undefined}>
          <TabSelector>
            {MESSAGE_TABS.map(item => (
              <NavMenuItem key={item.name} navItem={{ path: item.path, name: item.name }} />
            ))}
          </TabSelector>
          <SearchInput onChange={setSearchValue} value={searchValue} />
          <SearchTypePicker onChange={setSearchType} value={searchType} />
          {renderConversationList()}
        </ListSection>
        <ThreadSection isConversationSelected={selectedConversationId !== undefined}>
          <MessageThread
            conversationId={selectedConversationId}
            onCloseConversation={handleCloseConversation}
            searchValue={searchValue}
          />
        </ThreadSection>
        <ActionSection>
          <ActionsList />
        </ActionSection>
      </Container>
    </PageContainer>
  );
};

const MessagesWithData = () => {
  return (
    <MessagesProvider>
      <Messages />
    </MessagesProvider>
  );
};

export default MessagesWithData;
