import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import { TextMain } from '../typography/Texts';
import { usePopup } from '../../providers/PopupProvider';

const Description = styled(TextMain)`
  text-align: left;
  border-radius: 8px;
  background-color: ${theme.color.lighterGray};
  padding: 8px 4px;
  color: ${theme.color.darkerGray};
  overflow-y: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  cursor: pointer;
`;

const DescriptionFull = styled(Description)`
  padding: 8px;
  max-height: 600px;
  max-width: 400px;
  width: 300px;
  min-height: 100px;
  overflow-y: auto;
  cursor: auto;
  display: unset;
`;

interface Props {
  description?: string;
}

const CardDescription = ({ description }: Props) => {
  const { showPopover } = usePopup();

  const handleClick = (element: HTMLDivElement) => {
    showPopover(<DescriptionFull>{description}</DescriptionFull>, element);
  };

  if (!description) return null;
  return (
    <div onClick={event => handleClick(event.currentTarget)}>
      <Description>{description}</Description>
    </div>
  );
};

export default CardDescription;
