import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import TransactionsTable from './components/TransactionsTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Transactions = () => {
  return (
    <PageContainer>
      <Container>
        <TransactionsTable />
      </Container>
    </PageContainer>
  );
};

export default Transactions;
