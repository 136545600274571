import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import { DataTableRef } from '../../components/table/dataTableRef';
import { Column } from '../../components/table/DataTable';
import { CampaignAdminDto } from '../../services/Campaign/campaignService.dto';
import { TableFilter } from '../../components/table/FiltersPanel';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import CellWithEdit from '../../components/table/CellWithEdit';
import CellWithPopover from '../../components/table/CellWithPopover';
import CellWithNavigate from '../../components/table/CellWithNavigate';
import { useUpdateSortingBoost } from '../../components/sortingBoost/useUpdateSortingBoost';
import CampaignActions from './components/CampaignActions';
import CampaignDetailsCard from './components/CampaignDetailsCard';
import { displayCategories } from './utils/categoriesDisplayUtil';
import { useCampaignFilters } from './hooks/useCampaignFilters';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Campaigns = () => {
  const { t } = useTranslation('campaigns');
  const { campaignCategories: categories } = useGlobalData();

  const dataTableRef = useRef<DataTableRef>(null);
  const { handleCampaignSortingBoostUpdate } = useUpdateSortingBoost(() => dataTableRef.current?.refresh());
  const { onTableFiltered, filterInitState } = useCampaignFilters();

  const categoryPicklist = categories.map(category => category.displayName);

  const columns: Column<CampaignAdminDto>[] = [
    {
      id: 'id',
      title: t('campaigns.columns.id'),
      isSortable: true,
      minWidth: 40,
      maxWidth: 40,
      align: 'center',
      renderCell: data => (
        <CellWithPopover popover={<CampaignDetailsCard campaignId={data.id} />}>{data.id}</CellWithPopover>
      ),
    },
    {
      id: 'name',
      title: t('campaigns.columns.name'),
      isSortable: true,
      minWidth: 100,
      maxWidth: 400,
      renderCell: data => data.name,
    },
    {
      id: 'category',
      title: t('campaigns.columns.categories'),
      minWidth: 70,
      maxWidth: 100,
      renderCell: data => displayCategories(data.categories, categories),
    },
    {
      id: 'status',
      title: t('campaigns.columns.status'),
      minWidth: 70,
      maxWidth: 100,
      isSortable: true,
      renderCell: data => data.status,
    },
    {
      id: 'ownerId',
      title: t('campaigns.columns.owner-id'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/users/fundraisers/${data.ownerId}`}
          title={t('campaigns.go-to-fundraisers-tooltip', { id: data.ownerId })}>
          {data.ownerId}
        </CellWithNavigate>
      ),
    },
    {
      id: 'moneyCollected',
      title: t('campaigns.columns.money-collected'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.moneyCollected,
    },
    {
      id: 'moneyCollectedWithBoost',
      title: t('campaigns.columns.money-collected-with-boost'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.moneyCollectedWithBoost,
    },
    {
      id: 'adminSortingBoost',
      title: t('campaigns.columns.sorting-boost'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 100,
      align: 'center',
      renderCell: data => (
        <CellWithEdit onClick={element => handleCampaignSortingBoostUpdate(element, data)}>
          {data.adminSortingBoost}
        </CellWithEdit>
      ),
    },
    {
      id: 'actions',
      title: t('campaigns.columns.actions'),
      align: 'center',
      minWidth: 60,
      renderCell: data => <CampaignActions campaign={data} onUpdated={() => dataTableRef.current?.refresh()} />,
    },
  ];

  const filters: TableFilter[] = [
    { type: 'text', name: 'id', label: t('campaigns.filters.id') },
    { type: 'text', name: 'name', label: t('campaigns.filters.name') },
    {
      type: 'dropdown',
      name: 'category',
      label: t('campaigns.filters.category'),
      options: categoryPicklist,
    },
    { type: 'text', name: 'ownerId', label: t('campaigns.filters.owner-id') },
  ];

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={onTableFiltered}
          filterInitState={filterInitState}
        />
      </Container>
    </PageContainer>
  );
};

export default Campaigns;
