import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import { PinDto } from '../../features/users/components/VerificationPopover';
import { CredentialDto, CredentialFilterDto, EmployeeDto } from './authService.dto';

class AuthService {
  static BASE_URL = `${apiUrl}/api/auth`;

  login(email: string, password: string) {
    const url = `${AuthService.BASE_URL}/public/employee`;
    return apiService.post(url, {}, {}, { username: email, password });
  }

  fetchEmployees(): AxiosPromise<EmployeeDto[]> {
    const url = `${AuthService.BASE_URL}/admin/employees`;
    return apiService.get(url);
  }

  fetchCredentials(filters: CredentialFilterDto): AxiosPromise<PageableType<CredentialDto>> {
    const url = `${AuthService.BASE_URL}/admin/credentials`;
    return apiService.get(url, filters);
  }

  updateCredentials(credentialId: number, authorities: string[]) {
    const url = `${AuthService.BASE_URL}/admin/credentials/${credentialId}/authorities`;
    return apiService.put(url, {}, {}, authorities);
  }

  fetchAuthenticationPin(userId: number): AxiosPromise<PinDto> {
    const url = `${AuthService.BASE_URL}/admin/users/${userId}/last-device-verification-pin`;
    return apiService.get(url);
  }
}

const authService = new AuthService();

export { authService, AuthService };
