import React, { useCallback, useRef } from 'react';
import ReprocessIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';
import DataTable, { Column, TableFilterStateDto } from '../../../components/table/DataTable';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { shipmentService } from '../../../services/Shipment/shipmentService';
import { FailedShipmentDto } from '../../../services/Shipment/shipmentService.dto';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';

const ShipmentErrorsTable = () => {
  const { t } = useTranslation('shipments');
  const dataTableRef = useRef<DataTableRef>(null);
  const { handleError, errorMessage } = useGlobalError();

  const columns: Column<FailedShipmentDto>[] = [
    {
      id: 'id',
      title: t('shipment-errors.columns.id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'orderId',
      title: t('shipment-errors.columns.order-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.orderId,
    },
    {
      id: 'errorMessage',
      title: t('shipment-errors.columns.error-message'),
      isSortable: true,
      align: 'center',
      renderCell: data => `(${data.errorCode}) ${data.errorMessage}`,
    },
    {
      id: 'buyerId',
      title: t('shipment-errors.columns.buyer-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.buyerId,
    },
    {
      id: 'sellerId',
      title: t('shipment-errors.columns.seller-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.sellerId,
    },
    {
      id: 'from',
      title: t('shipment-errors.columns.from'),
      align: 'center',
      renderCell: renderShipmentFrom,
    },
    {
      id: 'to',
      title: t('shipment-errors.columns.to'),
      align: 'center',
      renderCell: renderShipmentTo,
    },
    {
      id: 'reprocess',
      title: t('shipment-errors.columns.reprocess'),
      align: 'center',
      renderCell: data => (
        <div title={t('shipment-errors.reprocess-tooltip')}>
          <ReprocessIcon style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => reprocessShipment(data.id)} />
        </div>
      ),
    },
  ];

  const reprocessShipment = (id: number) =>
    shipmentService
      .reprocessFailedShipment(id)
      .then(({ data }) => {
        if (data.errorMessage) {
          errorMessage(`(${data.errorCode}) ${data.errorMessage}`);
        } else {
          dataTableRef.current?.refresh();
        }
      })
      .catch(e => handleError(e));

  const onTableStateChange = useCallback((state: TableFilterStateDto<any>) => {
    return shipmentService.fetchFailedShipments(convertFilters(state)).then(response => response.data);
  }, []);

  const convertFilters = (filter: TableFilterStateDto<any>) => ({
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'id,desc',
  });

  return (
    <>
      <DataTable ref={dataTableRef} columns={columns} onTableStateChanged={onTableStateChange} />{' '}
    </>
  );
};

const renderShipmentFrom = ({
  shipFromName: name,
  shipFromCountry: country,
  shipFromCity: city,
  shipFromPostal: zip,
  shipFromAddress1: line1,
  shipFromAddress2: line2,
}: FailedShipmentDto) => renderShipmentData(name, country, city, zip, line1, line2);

const renderShipmentTo = ({
  shipToName: name,
  shipToCountry: country,
  shipToCity: city,
  shipToPostal: zip,
  shipToAddress1: line1,
  shipToAddress2: line2,
}: FailedShipmentDto) => renderShipmentData(name, country, city, zip, line1, line2);

const renderShipmentData = (name: string, country: string, city: string, zip: string, line1: string, line2: string) => (
  <div>
    {formatText(name)}
    <br />
    {formatText(`${country}, ${city}, ${zip}`)}
    <br />
    {formatText(line1)}
    <br />
    {formatText(line2)}
  </div>
);

const formatText = (data: string) => data?.replaceAll(' ', '\u00a0');

export default ShipmentErrorsTable;
