import React from 'react';
import styled from 'styled-components';
import { InputAdornment, TextField } from '@mui/material';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  background-color: ${theme.color.lighterGray};
  font-size: ${theme.font.size.s};
  font-family: ${theme.font.family.main};
  outline: 0;
  width: 150px;
  flex: 1;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type='number'] {
    -moz-appearance: textfield;
  }

  & input {
    font-size: ${theme.font.size.s};
    font-family: ${theme.font.family.main};
    padding: 0;
    height: 40px;
    text-align: left;
  }

  & textarea {
    font-size: ${theme.font.size.xs};
    font-family: ${theme.font.family.main};
    line-height: 2rem;
    padding: 0;
    min-height: 80px;
    max-height: 300px;
  }

  & label {
    font-size: 1.8rem;
  }
  & legend {
    font-size: 1.4rem;
  }

  & .MuiOutlinedInput-adornedStart {
    padding-left: 10px;
  }

  & .MuiOutlinedInput-root {
    padding-left: 5px;
  }
`;

interface Props {
  label: string;
  onChange: (value: string) => void;
  value: string;
  icon?: JSX.Element;
  multiline?: boolean;
  minWidth?: number;
  maxWidth?: number;
  inputMode?: 'text' | 'number';
  autoFocus?: boolean;
  disabled?: boolean;
  rows?: number;
  className?: string;
}

const InputWithLabel = (props: Props) => {
  return (
    <Container className={props.className}>
      <StyledTextField
        style={{ minWidth: props.minWidth, maxWidth: props.maxWidth }}
        label={props.label}
        value={props.value}
        type={props.inputMode || 'text'}
        onChange={e => props.onChange(e.target.value)}
        onFocus={e => props.autoFocus && e.target.select()}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows || 6}
        InputProps={{
          startAdornment: <InputAdornment position='start'>{props.icon || ''}</InputAdornment>,
        }}
        variant='outlined'
        autoFocus={props.autoFocus}
      />
    </Container>
  );
};

export default InputWithLabel;
