import { TableFilterStateDto } from '../../../components/table/DataTable';
import { ManualRefundFilterDto } from '../../../services/Payment/paymentService.dto';
import { parseMultiChoice } from '../../../components/table/filterConverterUtils';

export const convertFiltersToManualRefundFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>
): ManualRefundFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'creationDate,desc',
    orderId: filter.filtersMap?.get('orderId'),
    status: parseMultiChoice(filter, 'status'),
    payerId: filter.filtersMap?.get('payerId'),
    transactionId: filter.filtersMap?.get('transactionId'),
    refTransactionId: filter.filtersMap?.get('refTransactionId'),
  };
};
