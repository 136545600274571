import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authService } from '../services/Auth/authService';
import { UserDto } from '../services/User/userService.dto';
import { userService } from '../services/User/userService';
import { websocketService } from '../services/websocket/websocketService';
import { messageService } from '../services/Message/messageService';
import { MessageDto } from '../services/Message/messageService.dto';

interface AuthContextType {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  token: string | null;
  currentUser: UserDto | null;
  unreadMessagesCount: number;
  updateUnreadMessagesCount: () => void;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

function AuthProvider({ children }: any) {
  const { t } = useTranslation('account');
  const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));
  const [currentUser, setCurrentUser] = useState<UserDto | null>(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    let listenerId: string;
    if (token) {
      websocketService.connect();
      listenerId = websocketService.registerNewMsgListener(processNewIncomingMessage);
      getCurrentUser();
      updateUnreadMessagesCount();
    } else {
      websocketService.disconnect();
      setCurrentUser(null);
    }
    return () => {
      websocketService.disconnect();
      if (listenerId) websocketService.unregisterNewMsgListener(listenerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  async function login(email: string, password: string) {
    await authService
      .login(email, password)
      .then(response => {
        localStorage.setItem('authToken', response.data.authToken);
        setToken(response.data.authToken);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          throw new Error(t('login.invalid-credentials-msg'));
        } else {
          throw new Error(t('common:error.generic'));
        }
      });
  }

  function logout() {
    localStorage.removeItem('authToken');
    setToken(null);
    setCurrentUser(null);
  }

  function getCurrentUser() {
    userService
      .fetchUserCurrent()
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(() => {
        setCurrentUser(null);
        throw new Error(t('common:error.generic'));
      });
  }

  function updateUnreadMessagesCount() {
    messageService.fetchUnreadMessagesCount().then(response => setUnreadMessagesCount(response.data.messagesCount));
  }

  const processNewIncomingMessage = (message: MessageDto) => {
    updateUnreadMessagesCount();
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        token,
        currentUser,
        unreadMessagesCount,
        updateUnreadMessagesCount,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (authContext == null) {
    throw new Error('useAuth() called outside of an AuthProvider?');
  }
  return authContext;
};

export { AuthProvider, useAuth };
