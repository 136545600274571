import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import { TitleRecognitionDto, UnsafeTagDto } from './imageService.dto';

class ImageService {
  static BASE_URL = `${apiUrl}/api/image`;

  fetchTags(): AxiosPromise<UnsafeTagDto[]> {
    const url = `${ImageService.BASE_URL}/admin/unsafe-tags`;
    return apiService.get(url);
  }

  updateTag(tagId: number, igaUnsafe: boolean) {
    const url = `${ImageService.BASE_URL}/admin/unsafe-tags/${tagId}`;
    return apiService.put(url, {}, {}, { igaUnsafe });
  }

  getImageContent(imageUrl: string, withTopLabel?: boolean): AxiosPromise<TitleRecognitionDto> {
    const url = `${ImageService.BASE_URL}/public/image-content`;
    return apiService.get(url, { imageUrl, withTopLabel: withTopLabel || false });
  }
}

const imageService = new ImageService();

export { imageService, ImageService };
