import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import DropdownWithLabel from './DropdownWithLabel';
import CheckboxInputWithLabel from './CheckboxInputWithLabel';

const Container = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  align-items: center;
  outline: 0;
  flex: 1;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${theme.color.darkGray};
  cursor: pointer;

  &:hover {
    border-color: ${theme.color.shadow};
  }

  &:disabled:hover {
    border-color: ${theme.color.darkGray};
  }

  &:disabled {
    color: ${theme.color.darkerGray};
  }
`;

interface Props {
  onPriceChange: (value: number) => void;
  onCurrencyChange?: (value: string) => void;
  onNegotiableChange?: (value: boolean) => void;
  price: number;
  currency: string;
  negotiable?: boolean;
  canBeNegotiable?: boolean;
  availableCurrencies: string[];
  disabled?: boolean;
}

const PriceInput = ({
  price,
  currency,
  negotiable,
  onPriceChange,
  onCurrencyChange,
  onNegotiableChange,
  canBeNegotiable,
  availableCurrencies,
  disabled,
}: Props) => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <DropdownWithLabel
        value={currency}
        label={''}
        onChange={value => onCurrencyChange && onCurrencyChange(value || 'USD')}
        hideEmptyOption={true}
        options={availableCurrencies}
        minWidth={40}
        disabled={disabled}
      />
      <StyledInput
        value={price || ''}
        inputMode={'numeric'}
        onChange={e => onPriceChange(Number.parseInt(e.target.value || '0'))}
        disabled={disabled}
      />
      {canBeNegotiable && (
        <CheckboxInputWithLabel
          label={t('negotiable')}
          checked={!!negotiable}
          onChange={value => onNegotiableChange && onNegotiableChange(value)}
        />
      )}
    </Container>
  );
};

export default PriceInput;
