import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { CloseButton, ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../components/typography/PopoverTypography';
import DropdownWithLabel from '../../../components/inputs/DropdownWithLabel';
import {
  REFUND_REQUEST_STATUSES,
  RefundRequestDto,
  RefundRequestStatus,
} from '../../../services/Order/orderService.dto';
import closeIcon from '../../../assets/svg/close.svg';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  refundRequest: RefundRequestDto;
  onConfirmPress: (notes: string, status: RefundRequestStatus) => void;
  onClosePressed: () => void;
}

const RefundRequestUpdateDialog = ({ refundRequest, onConfirmPress, onClosePressed }: Props) => {
  const { t } = useTranslation('orders');

  const [notes, setNotes] = useState(refundRequest.notes || '');
  const [status, setStatus] = useState<RefundRequestStatus>(refundRequest.status);

  return (
    <Dialog open={true} onClose={onClosePressed}>
      <FormContainer>
        <FormHeader>
          {t('update-refund-request-dialog.header')} <b>{`${refundRequest.orderId}`} </b>
        </FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />

        <DropdownWithLabel
          label={t('update-refund-request-dialog.status')}
          value={status}
          onChange={value => setStatus(value as RefundRequestStatus)}
          options={[...REFUND_REQUEST_STATUSES]}
          hideEmptyOption={true}
          minWidth={300}
        />

        <InputWithLabel
          label={t('update-refund-request-dialog.notes')}
          onChange={setNotes}
          value={notes}
          multiline={true}
          minWidth={300}
        />

        <ConfirmButton
          label={t('update-refund-request-dialog.confirm-btn')}
          onClick={() => onConfirmPress(notes, status)}
          disabled={!notes && status === refundRequest.status}
        />
      </FormContainer>
    </Dialog>
  );
};

export default RefundRequestUpdateDialog;
