import i18n from '../assets/translations/config';

class DateUtils {
  formatTime(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
    return Intl.DateTimeFormat(i18n.language, options).format(this.createDateAsUTC(date));
  }

  formatDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    return Intl.DateTimeFormat(i18n.language, options).format(this.createDateAsUTC(date));
  }

  formatDateDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return Intl.DateTimeFormat(i18n.language, options).format(this.createDateAsUTC(date));
  }

  formatMonthYear(date: Date) {
    const options: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
    return Intl.DateTimeFormat(i18n.language, options).format(date);
  }

  formatYearMonthDay(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  areDatesOnTheSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  createDateAsUTC(date: Date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  // Returns string in format mm-dd-yyyy, for example: 2021-03-18
  toLocalDate(date: Date) {
    return this.createDateAsUTC(date).toISOString().split('T')[0];
  }

  parseReportDate(date: string) {
    const [year, month, day] = date.split('-').map(part => Number(part));
    return new Date(year, month - 1, day);
  }

  formatTimeElapsed(date: Date) {
    const now = new Date();
    const diffInMinutes = Math.round((now.getTime() - this.createDateAsUTC(date).getTime()) / (1000 * 60));
    if (diffInMinutes === 0) return '<' + i18n.t('dates.minute', { count: 1 });
    if (diffInMinutes < 60) return i18n.t('dates.minute', { count: diffInMinutes });
    const diffInHours = Math.round(diffInMinutes / 60);
    if (diffInHours < 24) return i18n.t('dates.hour', { count: diffInHours });
    return this.formatDate(date);
  }

  getDateAgo(daysAgo: number) {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date;
  }

  toLocalDateTimeString(ldt: string) {
    const date = new Date(ldt);
    return `${this.formatDate(date)}, ${this.formatTime(date)}`;
  }

  formatCampaignTimeline(timelineFrom: string, timelineTo?: string) {
    let timeline = dateUtils.formatDate(new Date(timelineFrom));
    if (timelineTo) {
      timeline = `${timeline} - ${dateUtils.formatDate(new Date(timelineTo))}`;
    }
    return timeline;
  }

  parseBackendDate(date: string): Date {
    return new Date(date);
  }

  dateWithDaysOffset(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
}

export const dateUtils = new DateUtils();
