import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { prepareInitialFilter } from '../../../components/table/filterConverterUtils';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { campaignService } from '../../../services/Campaign/campaignService';
import { usePopup } from '../../../providers/PopupProvider';
import CampaignDetailsCard from '../components/CampaignDetailsCard';
import { convertFiltersToCampaignFilterDto } from '../utils/filterConverter';

type UrlParams = {
  campaignId: string;
};

export const useCampaignFilters = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<UrlParams>();
  const { campaignCategories: categories } = useGlobalData();
  const { showPopover } = usePopup();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToCampaignFilterDto(tableState, categories);
    navigate(`/campaigns/${filter.id || ''}`);
    return campaignService.fetchCampaigns(filter).then(response => {
      if (filter.id && response.data.content?.length === 1) {
        const campaignInfo = response.data.content[0];
        showPopover(<CampaignDetailsCard campaignId={campaignInfo.id} />);
      }
      return response.data;
    });
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(campaignId), [campaignId]);

  return { onTableFiltered, filterInitState };
};
