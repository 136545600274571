import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImagePreview from '../../../components/image/ImagePreview';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { useUserDetails } from '../../users/hooks/useUserDetails';
import { CardContainer, CardCreatedInfo, CardHeader, Column, Row } from '../../../components/cards/CardCommons';
import CardParamInfo from '../../../components/cards/CardParamInfo';
import { CommentDto } from '../../../services/Campaign/campaignService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import { useCommentDetails } from '../hooks/useCommentDetails';

const CommentCardContainer = styled(CardContainer)`
  width: 500px;
  min-height: 200px;
`;

interface Props {
  commentId?: number;
  commentInfo?: CommentDto;
}

const CommentDetailsCard = ({ commentId, commentInfo }: Props) => {
  const { t } = useTranslation('campaigns');

  const { isLoading: isCommentLoading, comment } = useCommentDetails(commentId, commentInfo);
  const { isLoading: isAuthorLoading, info: authorInfo, user } = useUserDetails(comment?.authorId);

  const imgUrl = comment?.imageUrl ? [comment.imageUrl] : undefined;

  if (!comment || isCommentLoading)
    return (
      <CardContainer>
        <LoadingIndicator />
      </CardContainer>
    );

  return (
    <CommentCardContainer>
      <CardHeader>{t('comment-details.header', { id: comment.id })}</CardHeader>
      <Row>
        <ImagePreview imageUrls={imgUrl} />
        <Column>
          <CardCreatedInfo>{dateUtils.formatDateDate(new Date(comment.created))}</CardCreatedInfo>
          {comment.content && <CardParamInfo label={t('comment-details.content-label')} value={comment.content} />}
          <CardParamInfo
            label={t('comment-details.author-label')}
            value={authorInfo}
            popover={<UserDetailsCard userId={comment.authorId} userInfo={user} />}
            loading={isAuthorLoading}
          />
          <CardParamInfo label={t('comment-details.replies-label')} value={comment.repliesCount.toString()} />
          <CardParamInfo label={t('comment-details.likes-label')} value={comment.likesCount.toString()} />
        </Column>
      </Row>
    </CommentCardContainer>
  );
};

export default CommentDetailsCard;
