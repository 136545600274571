import React, { useRef } from 'react';
import styled from 'styled-components';
import { DataTableRef } from '../../components/table/dataTableRef';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import WithdrawMoneyForm from './components/WithdrawMoneyForm';
import WithdrawMoneyTable from './components/WithdrawMoneyTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const WithdrawMoney = () => {
  const dataTableRef = useRef<DataTableRef>(null);

  return (
    <PageContainer>
      <Container>
        <WithdrawMoneyForm onNewReport={() => dataTableRef.current?.refresh()} />
        <WithdrawMoneyTable ref={dataTableRef} />
      </Container>
    </PageContainer>
  );
};

export default WithdrawMoney;
