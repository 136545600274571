import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormText } from '../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 400px;
`;

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

interface Props {
  anchor: SVGSVGElement | HTMLDivElement | null;
  objectId: number;
  onClosePressed: () => void;
  onBlockPressed: () => void;
}

const UserBlockReminderPopover = (props: Props) => {
  const { t } = useTranslation('users');
  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormText>{t('reminder-label', { id: props.objectId })}</FormText>
        <ButtonsWrapper>
          <ConfirmButton label={t('block-btn')} onClick={props.onBlockPressed} />
          <ConfirmButton label={t('block-later-btn')} onClick={props.onClosePressed} />
        </ButtonsWrapper>
      </FormContainer>
    </Popover>
  );
};

export default UserBlockReminderPopover;
