import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SyncIcon from '@mui/icons-material/Sync';
import { useItemForm } from '../../hooks/ItemContext';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import { theme } from '../../../../assets/styles/theme';
import { shipmentService } from '../../../../services/Shipment/shipmentService';
import { useGlobalError } from '../../../../providers/GlobalErrorProvider';
import { DEFAULT_ITEM_LOCATION, ItemLocationInfoDto, LocationSource } from '../../../../services/Item/itemService.dto';
import { DEFAULT_COUNTRY_CODE } from '../../../../services/Shipment/shipmentService.dto';
import FormRow from './FormRow';

const Container = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const SyncButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const VALID_ZIP_LEN = 5;

const LocationInputRow = () => {
  const { t } = useTranslation('items');
  const { handleError } = useGlobalError();
  const { formData, setItemLocation } = useItemForm();
  const itemLocation = formData.itemLocation;
  const userLocation = formData.user?.location;

  const [zip, setZip] = useState(itemLocation.zipCode);
  const [city, setCity] = useState(itemLocation.city);
  const [state, setState] = useState(itemLocation.state);
  const [lat, setLat] = useState<number | undefined>(itemLocation.latitude);
  const [lon, setLon] = useState<number | undefined>(itemLocation.longitude);
  const [locationSource, setLocationSource] = useState<LocationSource | undefined>(itemLocation.locationSource);

  useEffect(() => {
    const itemLocation: ItemLocationInfoDto = {
      latitude: lat,
      longitude: lon,
      zipCode: zip,
      state,
      city,
      countryCode: DEFAULT_COUNTRY_CODE,
      locationSource,
    };
    setItemLocation(itemLocation);
  }, [zip, city, state, lat, lon]);

  useEffect(() => {
    if (userLocation) {
      setZip(userLocation?.zip);
      setCity(userLocation?.city);
      setState(userLocation?.state);
      setLat(userLocation?.lat);
      setLon(userLocation?.lon);
      setLocationSource('PROFILE');
    } else {
      setItemLocation(DEFAULT_ITEM_LOCATION);
      setZip(DEFAULT_ITEM_LOCATION.zipCode);
      setCity(DEFAULT_ITEM_LOCATION.city);
      setState(DEFAULT_ITEM_LOCATION.state);
      setLat(DEFAULT_ITEM_LOCATION.latitude);
      setLon(DEFAULT_ITEM_LOCATION.longitude);
      setLocationSource(DEFAULT_ITEM_LOCATION.locationSource);
    }
  }, [userLocation]);

  const syncCoords = () => {
    if (!!zip && zip.length === VALID_ZIP_LEN) {
      shipmentService
        .fetchGeocoding(zip)
        .then(response => {
          setLat(response.data.coordinates.latitude);
          setLon(response.data.coordinates.longitude);
          setLocationSource('ZIP_CODE');
        })
        .catch(e => {
          setLat(undefined);
          setLon(undefined);
          setLocationSource(undefined);
          handleError(e);
        });
    } else {
      setLat(undefined);
      setLon(undefined);
    }
  };

  return (
    <FormRow label={t('add-item.location')}>
      <Container>
        <Row>
          <StyledInput label={t('add-item.zip')} onChange={setZip} value={zip || ''} />
          <StyledInput label={t('add-item.city')} onChange={setCity} value={city || ''} />
          <StyledInput label={t('add-item.state')} onChange={setState} value={state || ''} />
        </Row>
        <Row>
          <StyledInput
            label={t('add-item.lat')}
            onChange={lat => setLat(Number.parseFloat(lat))}
            disabled={true}
            value={lat ? lat.toString() : ''}
          />
          <StyledInput
            label={t('add-item.lon')}
            onChange={lon => setLon(Number.parseFloat(lon))}
            disabled={true}
            value={lon ? lon.toString() : ''}
          />
          <SyncButton title={t('add-item.sync-coords-btn')} onClick={syncCoords}>
            <SyncIcon style={{ color: theme.color.red, fontSize: 24 }} />
          </SyncButton>
        </Row>
      </Container>
    </FormRow>
  );
};

export default LocationInputRow;
