import React from 'react';
import { useTranslation } from 'react-i18next';
import Auctions from './Auctions';

const DeletedAuctions = () => {
  const { t } = useTranslation('items');
  return <Auctions title={t('auctions.title-deleted')} auctionStatuses={['CANCELED', 'DELETED']} />;
};

export default DeletedAuctions;
