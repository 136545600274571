import React from 'react';
import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import ChatIcon from '@mui/icons-material/Chat';
import RefundRequestIcon from '@mui/icons-material/MoneyOff';
import { cancelableOrders, OrderDto } from '../../../services/Order/orderService.dto';
import { usePopup } from '../../../providers/PopupProvider';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { useAuth } from '../../../providers/AuthProvider';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-self: center;
  color: inherit;
  text-decoration: none;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  order: OrderDto;
  onCancelOrder: (orderId: number) => void;
  onContactUser: (userId: number) => void;
}

const OrderActions = ({ order, onCancelOrder, onContactUser }: Props) => {
  const { t } = useTranslation('orders');
  const { showDialog, hideDialog } = usePopup();
  const { currentUser } = useAuth();

  const isOrderCancelable = () => cancelableOrders.includes(order.status);

  const showConfirmDialog = () => {
    const handleConfirm = () => {
      onCancelOrder(order.id);
      hideDialog();
    };
    showDialog(
      <ConfirmDialog msg={t('confirm-cancel', { id: order.id })} onYesClick={handleConfirm} onNoClick={hideDialog} />
    );
  };

  return (
    <Container>
      {isOrderCancelable() && (
        <StyledButton title={t('cancel-tooltip')} onClick={showConfirmDialog}>
          <CancelIcon style={iconStyle} />
        </StyledButton>
      )}
      {currentUser?.id !== order.buyerId && (
        <StyledButton title={t('contact-tooltip')} onClick={() => onContactUser(order.buyerId)}>
          <ChatIcon style={iconStyle} />
        </StyledButton>
      )}
      {order.refundRequested && (
        <StyledLink title={t('go-to-refund-request-tooltip')} href={`/orders/refund-requests/${order.id}`}>
          <RefundRequestIcon style={iconStyle} />
        </StyledLink>
      )}
    </Container>
  );
};

export default OrderActions;
