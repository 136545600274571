import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../assets/styles/theme';
import cameraIcon from '../../../../assets/svg/camera-chat.svg';
import sendIcon from '../../../../assets/svg/send.svg';
import optionsIcon from '../../../../assets/svg/three-vertical-dots.svg';
import { useMessages } from '../../../../providers/messages/MessagesProvider';
import ActionsDrawer from '../actions/ActionsDrawer';
import { useAuth } from '../../../../providers/AuthProvider';
import { ConversationStatus } from '../../../../services/Message/messageService.dto';
import { useGlobalError } from '../../../../providers/GlobalErrorProvider';
import { TextSmall } from '../../../../components/typography/Texts';

const MSG_CHARACTERS_LIMIT = 3000;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  justify-content: flex-end;
  align-self: flex-end;
  bottom: 0;
  left: 0;
  height: 50px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 0 10px;
  display: flex;
  flex: 1;
  align-items: center;
  height: 80%;
`;

const StyledTextArea = styled.textarea`
  background-color: ${theme.color.lighterGray};
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.6rem;
  letter-spacing: -0.4px;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  resize: none;
  overflow: hidden;
  outline: 0;
  white-space: pre-wrap;
  border-radius: 22px;
  padding: 6px 10px;
  border: 2px solid ${theme.color.lighterGray};

  &:focus {
    border: 2px solid ${theme.color.darkerGray};
    line-height: 2.2rem;
    overflow: auto;
    scroll-padding-bottom: 20px;
    padding-bottom: 20px;
  }
`;

const InputToolTip = styled(TextSmall)`
  position: absolute;
  bottom: 0;
  right: 5%;
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.xs};
  color: ${theme.color.darkerGray};
`;

const CameraBtn = styled.img`
  cursor: pointer;
`;

const OptionsBtn = styled.img`
  cursor: pointer;
  padding: 10px 7px 10px 10px;
`;

const SendBtn = styled.img<{ isVisible: boolean }>`
  cursor: pointer;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const ImgInput = styled.input`
  display: none;
`;

const ActionsDrawerContainer = styled.div`
  display: flex;
  ${theme.mq.desktop} {
    display: none;
  }
`;

const MessageInput = () => {
  const { sendTextMessage, sendPhotoMessage, conversation } = useMessages();
  const [msg, setMsg] = useState('');
  const [img, setImg] = useState('');
  const { t } = useTranslation('messages');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { currentUser } = useAuth();
  const { handleError } = useGlobalError();
  const [isActionsDrawerVisible, setIsActionsDrawerVisible] = useState(false);
  const [isInputToolTipVisible, setIsInputToolTipVisible] = useState(false);

  const toggleActionsDrawer = () => setIsActionsDrawerVisible(!isActionsDrawerVisible);

  const isInputVisible =
    conversation?.status === ConversationStatus.ONGOING &&
    conversation.supporter?.id === currentUser?.id &&
    !conversation.resolved;

  const handleSendMsg = () => {
    if (inputRef.current) inputRef.current.blur();
    if (msg !== '') {
      sendTextMessage(msg.trim())
        .then(() => setMsg(''))
        .catch(error => handleError(error, error.message));
    }
  };

  const handleSendImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      setImg(e.target.value);
      sendPhotoMessage(file)
        .then(() => setImg(''))
        .catch(error => handleError(error, error.message));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && msg !== '') handleSendMsg();
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value.slice(0, MSG_CHARACTERS_LIMIT).trimStart();
    setMsg(newValue);
    setIsInputToolTipVisible(newValue !== '');
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.rows = 6;
    e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
    setIsInputToolTipVisible(msg !== '');
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.rows = 1;
    e.currentTarget.scrollTop = 0;
    setIsInputToolTipVisible(false);
  };

  return (
    <Container onKeyUp={handleKeyPress}>
      {isInputVisible && (
        <>
          <div>
            <label htmlFor='img-input'>
              <CameraBtn src={cameraIcon} alt={'camera button'} />
            </label>
            <ImgInput id='img-input' type='file' value={img} accept='image/*' onChange={handleSendImg} />
          </div>
          <InputContainer>
            <StyledTextArea
              rows={1}
              value={msg}
              ref={inputRef}
              placeholder={t('input-placeholder')}
              onChange={handleTextChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            {isInputToolTipVisible && (
              <InputToolTip>
                <b>{t('input-tooltip-keys')}</b> {t('input-tooltip-info')}
              </InputToolTip>
            )}
          </InputContainer>
          <SendBtn src={sendIcon} alt={'send button'} isVisible={msg !== ''} onClick={handleSendMsg} />
        </>
      )}

      <ActionsDrawerContainer>
        <OptionsBtn src={optionsIcon} onClick={toggleActionsDrawer} />
        <ActionsDrawer toggleDrawer={toggleActionsDrawer} isVisible={isActionsDrawerVisible} />
      </ActionsDrawerContainer>
    </Container>
  );
};

export default MessageInput;
