import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import { Column } from '../../components/table/DataTable';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import { TableFilter } from '../../components/table/FiltersPanel';
import { DataTableRef } from '../../components/table/dataTableRef';
import { orderService } from '../../services/Order/orderService';
import { ORDER_STATUSES, OrderDto } from '../../services/Order/orderService.dto';
import { dateUtils } from '../../utils/dateUtils';
import CellWithPopover from '../../components/table/CellWithPopover';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import ItemDetailsCard from '../items/components/ItemDetailsCard';
import CampaignDetailsCard from '../campaigns/components/CampaignDetailsCard';
import UserDetailsCard from '../users/components/UserDetailsCard';
import OrderActions from './components/OrderActions';
import { useOrderFilters } from './hooks/useOrderFilters';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Orders = () => {
  const { t } = useTranslation('orders');
  const navigate = useNavigate();
  const { handleError } = useGlobalError();
  const dataTableRef = useRef<DataTableRef>(null);
  const { onTableFiltered, filterInitState } = useOrderFilters();

  const columns: Column<OrderDto>[] = [
    {
      id: 'id',
      title: t('columns.id'),
      isSortable: true,
      maxWidth: 20,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'created',
      title: t('columns.created'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 60,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.created)),
    },
    {
      id: 'orderType',
      title: t('columns.type'),
      maxWidth: 40,
      isSortable: true,
      renderCell: data => data.type,
    },
    {
      id: 'status',
      title: t('columns.status'),
      maxWidth: 40,
      isSortable: true,
      renderCell: data => data.status,
    },
    {
      id: 'basePrice',
      title: t('columns.base-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.basePrice,
    },
    {
      id: 'shipmentPrice',
      title: t('columns.shipment-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.shipmentPrice,
    },
    {
      id: 'transactionFee',
      title: t('columns.transaction-fee'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.transactionFee,
    },
    {
      id: 'transactionFeeAddedAmount',
      title: t('columns.transaction-fee-added-amount'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.transactionFeeAddedAmount,
    },
    {
      id: 'amountTotal',
      title: t('columns.total-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.amountTotal,
    },
    {
      id: 'currency',
      title: t('columns.currency'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => data.currency,
    },
    {
      id: 'campaignId',
      title: t('columns.campaign-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => (
        <CellWithPopover popover={<CampaignDetailsCard campaignId={data.campaignId} />}>
          {data.campaignId}
        </CellWithPopover>
      ),
    },
    {
      id: 'itemId',
      title: t('columns.item-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data =>
        data.itemId && (
          <CellWithPopover popover={<ItemDetailsCard itemId={data.itemId} />}>{data.itemId}</CellWithPopover>
        ),
    },
    {
      id: 'buyerId',
      title: t('columns.buyer-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => (
        <CellWithPopover popover={<UserDetailsCard userId={data.buyerId} />}>{data.buyerId}</CellWithPopover>
      ),
    },
    {
      id: 'shipmentId',
      title: t('columns.shipment-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => data.shipmentId,
    },
    {
      id: 'actions',
      title: t('columns.actions'),
      align: 'center',
      maxWidth: 30,
      renderCell: data => (
        <OrderActions order={data} onCancelOrder={handleCancelOrder} onContactUser={handleContactUser} />
      ),
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: t('filters.id') },
    { type: 'date', name: 'date', label: t('filters.date') },
    {
      type: 'multi-choice',
      name: 'type',
      options: ['PURCHASE', 'DONATION'],
      label: t('filters.type'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...ORDER_STATUSES],
      label: t('filters.status'),
    },
    { type: 'numeric', name: 'basePriceFrom', label: t('filters.base-price-from') },
    { type: 'numeric', name: 'basePriceTo', label: t('filters.base-price-to') },
    { type: 'numeric', name: 'campaignId', label: t('filters.campaign-id') },
    { type: 'numeric', name: 'itemId', label: t('filters.item-id') },
    { type: 'numeric', name: 'buyerId', label: t('filters.buyer-id') },
  ];

  const handleCancelOrder = (orderId: number) => {
    dataTableRef.current?.refresh();
    orderService
      .cancelOrder(orderId)
      .then(() => dataTableRef.current?.refresh())
      .catch(e => handleError(e));
  };

  const handleContactUser = (userId: number) => {
    messageService.createConversation(userId).then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));
  };

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          filterInitState={filterInitState}
          onTableStateChanged={onTableFiltered}
        />
      </Container>
    </PageContainer>
  );
};

export default Orders;
