import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from './FormRow';

const DescriptionInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setDescription } = useItemForm();

  return (
    <FormRow label={t('add-item.description')}>
      <InputWithLabel
        label={''}
        onChange={setDescription}
        value={formData.description || ''}
        multiline={true}
        rows={1}
      />
    </FormRow>
  );
};

export default DescriptionInputRow;
