import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

const StyledButton = styled.div`
  background-color: ${theme.color.red};
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px ${theme.color.darkGray};
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 10px;
`;

interface Props extends PropsWithChildren {
  onClick?: () => void;
}

const FloatingButton = ({ onClick, children }: Props) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export default FloatingButton;
