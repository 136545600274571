import React, { useState } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PartialMessageUpdateDto } from '../../../services/Maintenance/maintenanceService.dto';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';

const FormContainer = styled.div`
  padding: 10px;
  position: relative;
`;

interface Props {
  anchor: HTMLDivElement | SVGSVGElement;
  JSONString?: string;
  onClosePressed: () => void;
  partialMessage?: PartialMessageUpdateDto;
  onConfirm?: (partialMessage: PartialMessageUpdateDto) => void;
}

const JSONPopover = (props: Props) => {
  const { t } = useTranslation();
  const [partialMessage, setPartialMessage] = useState<PartialMessageUpdateDto | undefined>(props.partialMessage);

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <ReactJson
          src={partialMessage ? partialMessage : JSON.parse(props.JSONString || '')}
          name={false}
          displayDataTypes={false}
          onEdit={partialMessage ? edit => setPartialMessage(edit.updated_src as PartialMessageUpdateDto) : false}
        />
        {partialMessage && (
          <>
            <ConfirmButton
              label={t('confirm-btn')}
              onClick={() => props.onConfirm && props.onConfirm(partialMessage)}
            />
            <ConfirmButton label={t('cancel')} onClick={props.onClosePressed} />
          </>
        )}
      </FormContainer>
    </Popover>
  );
};

export default JSONPopover;
