import React, { ReactNode } from 'react';
import styled from 'styled-components';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { usePopup } from '../../providers/PopupProvider';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DataContainer = styled.div`
  text-align: right;
  width: 100%;
  padding-right: 5px;
`;

const ButtonContainer = styled.div`
  text-align: left;
  width: 100%;
  padding-left: 5px;
  align-items: center;
  display: flex;
`;

interface Props {
  popover: ReactNode;
  attachToAnchor?: boolean;
  children: ReactNode;
}

const CellWithPopover = ({ popover, attachToAnchor, children }: Props) => {
  const { showPopover } = usePopup();
  return (
    <Container onClick={element => showPopover(popover, attachToAnchor ? element.currentTarget : undefined)}>
      <DataContainer>{children}</DataContainer>
      <ButtonContainer>
        <SearchSharpIcon style={{ fontSize: 18, cursor: 'pointer' }} />
      </ButtonContainer>
    </Container>
  );
};

export default CellWithPopover;
