import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/containers/PageContainer';

const Title = styled.h1`
  font-size: ${theme.font.size.s};
  margin: 20px 0;
  padding-left: ${theme.paddingStandard}px;
  ${theme.mq.phone} {
    font-size: ${theme.font.size.m};
    padding-left: ${theme.navbarPadding}px;
  }
`;

const Home = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Title>{t('home.title')}</Title>
    </PageContainer>
  );
};

export default Home;
