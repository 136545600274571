import { TableFilterStateDto } from '../../../components/table/DataTable';
import { ReportFilterDto } from '../../../services/Report/reportService.dto';
import { dateUtils } from '../../../utils/dateUtils';

export const convertFiltersToReportFilterDto = (filter: TableFilterStateDto<Map<string, any>>): ReportFilterDto => {
  const searchedWordsList = filter.filtersMap?.get('searchValue')
    ? filter.filtersMap.get('searchValue').replaceAll(' ', ',')
    : null;

  const dateFrom =
    filter.filtersMap?.get('dateFrom') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateFrom')));

  const dateTo = filter.filtersMap?.get('dateTo') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateTo')));

  const hidden = filter.filtersMap?.get('hidden');
  const visible = filter.filtersMap?.get('visible');

  let hiddenFilter;
  if (!(hidden && visible)) {
    if (hidden) hiddenFilter = true;
    if (visible) hiddenFilter = false;
  }

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
    hidden: hiddenFilter,
    objectType: filter.filtersMap?.get('objectType'),
    searchedWords: searchedWordsList,
    reportDateFrom: dateFrom,
    reportDateTo: dateTo,
  };
};
