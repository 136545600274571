import React from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../buttons/popoverButtons';
import { FormText } from '../typography/PopoverTypography';

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 350px;
`;

interface Props {
  anchor: HTMLElement | SVGSVGElement | null;
  onClosePressed: () => void;
  onContinuePressed: () => void;
  confirmMessage: string;
}

const ConfirmPopover = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <FormContainer>
        <FormText>{props.confirmMessage}</FormText>
        <ButtonsWrapper>
          <ConfirmButton label={t('confirm-btn')} onClick={props.onContinuePressed} />
          <ConfirmButton label={t('cancel')} onClick={props.onClosePressed} />
        </ButtonsWrapper>
      </FormContainer>
    </Popover>
  );
};

export default ConfirmPopover;
