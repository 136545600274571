import React, { useMemo } from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import UserSelector from '../../../users/components/userSelector/UserSelector';
import { theme } from '../../../../assets/styles/theme';
import { useItemForm } from '../../hooks/ItemContext';
import FormRow from './FormRow';

const Container = styled.div`
  display: flex;
  padding: 0 5px;
`;

const Icon = styled(ClearIcon)`
  cursor: pointer;
  color: ${theme.color.red};
  margin: 0 10px;
`;

const UserInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setUser } = useItemForm();
  const { user } = formData;
  const userInfo = useMemo(() => (user ? `${user.firstName} ${user.lastName} (#${user.id})` : ''), [user]);
  return (
    <FormRow label={t('add-item.user')}>
      <Container>
        {!!user && (
          <>
            <span>{userInfo}</span>
            {!formData.id && <Icon style={{ fontSize: '20px' }} onClick={() => setUser(undefined)} />}
          </>
        )}
        {!user && <UserSelector onUserSelected={setUser} />}
      </Container>
    </FormRow>
  );
};

export default UserInputRow;
