import { Reducer, useCallback, useReducer } from 'react';
import PageableType from '../../services/utils/pageableType';

type SortDirectionType = 'asc' | 'desc' | undefined;

enum DataTableActionTypes {
  FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST',
  FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
  CHANGE_SORT = 'CHANGE_SORT',
  CHANGE_PAGE = 'CHANGE_PAGE',
  CHANGE_FILTER = 'CHANGE_FILTER',
}

type Action<DataType, FilterType> =
  | { type: DataTableActionTypes.CHANGE_SORT; payload?: string }
  | { type: DataTableActionTypes.CHANGE_PAGE; payload: number }
  | { type: DataTableActionTypes.CHANGE_FILTER; payload: FilterType }
  | { type: DataTableActionTypes.FETCH_DATA_REQUEST }
  | { type: DataTableActionTypes.FETCH_DATA_SUCCESS; payload: PageableType<DataType> };

interface State<DataType, FilterType> {
  rows: DataType[];
  isLoading: boolean;
  page: number;
  totalNumberOfElements: number;
  rowsPerPage: number;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  filterData?: FilterType;
}

const initialState = {
  rows: [],
  isLoading: true,
  page: 0,
  totalNumberOfElements: 0,
  rowsPerPage: 20,
};

const reducer = <DataType, FilterType>(state: State<DataType, FilterType>, action: Action<DataType, FilterType>) => {
  switch (action.type) {
    case DataTableActionTypes.FETCH_DATA_REQUEST:
      return { ...state, isLoading: true };
    case DataTableActionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.content || [],
        totalNumberOfElements: action.payload.totalElements || 0,
      };
    case DataTableActionTypes.CHANGE_SORT:
      return {
        ...state,
        page: 0,
        sortBy: state.sortBy === action.payload && state.sortDirection === 'desc' ? undefined : action.payload,
        sortDirection: getNextSortDirection(state.sortBy !== action.payload, state.sortDirection),
      };
    case DataTableActionTypes.CHANGE_FILTER:
      return {
        ...state,
        page: 0,
        filterData: action.payload,
      };
    case DataTableActionTypes.CHANGE_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

const getNextSortDirection = (isSortByChanged: boolean, prevSortDirection: SortDirectionType): SortDirectionType => {
  if (isSortByChanged) return 'asc';
  else {
    if (prevSortDirection === 'asc') return 'desc';
    else if (prevSortDirection === 'desc') return undefined;
    else return 'asc';
  }
};

export const useDataTableReducer = <DataType, FilterType>() => {
  const [tableState, dispatch] = useReducer<Reducer<State<DataType, FilterType>, Action<DataType, FilterType>>>(
    reducer,
    initialState
  );

  const onSortChanged = (sortBy: string) => {
    dispatch({ type: DataTableActionTypes.CHANGE_SORT, payload: sortBy });
  };

  const onPageChanged = (page: number) => {
    dispatch({ type: DataTableActionTypes.CHANGE_PAGE, payload: page });
  };

  const onFilterChanged = useCallback((filterData: FilterType) => {
    dispatch({ type: DataTableActionTypes.CHANGE_FILTER, payload: filterData });
  }, []);

  const onFetchRequest = useCallback(() => {
    dispatch({ type: DataTableActionTypes.FETCH_DATA_REQUEST });
  }, []);

  const onFetchSuccess = useCallback((response: PageableType<DataType>) => {
    dispatch({ type: DataTableActionTypes.FETCH_DATA_SUCCESS, payload: response });
  }, []);
  return { tableState, onSortChanged, onPageChanged, onFilterChanged, onFetchRequest, onFetchSuccess };
};
