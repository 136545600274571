import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { useItemForm } from '../../hooks/ItemContext';
import { theme } from '../../../../assets/styles/theme';
import InputImage from '../../../../components/image/InputImage';
import FormRow from './FormRow';

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  padding: 5px;
  overflow-x: auto;
  max-width: 500px;
  border: 2px dotted ${props => (props.isDragging ? theme.color.darkerGray : theme.color.white)};
  border-radius: 10px;
`;

const PhotosContainer = styled.div`
  display: flex;
  padding: 0 5px;
  gap: 5px;
`;

const AddPhotoButton = styled.div`
  width: 100px;
  aspect-ratio: 1;
  border: 2px dotted ${theme.color.red};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgInput = styled.input`
  display: none;
`;

const PhotosInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, addPhoto, deletePhoto } = useItemForm();
  const [isDragging, setIsDragging] = useState(false);

  const handleAddImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files || []).forEach(file => addPhoto(file));
    e.target.value = '';
  };

  const handleDropImg = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Array.from(e.dataTransfer.files || []).forEach(file => addPhoto(file));
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  return (
    <FormRow label={t('add-item.photos')}>
      <Container
        isDragging={isDragging}
        onDrop={handleDropImg}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}>
        <label htmlFor='img-input'>
          <AddPhotoButton>
            <AddIcon style={{ color: theme.color.red, fontSize: 40 }} />
          </AddPhotoButton>
        </label>
        <ImgInput id='img-input' type='file' multiple accept='image/*' onInput={handleAddImg} />
        <PhotosContainer>
          {formData.photos.map(img => (
            <InputImage key={img.id} img={img} onDelete={deletePhoto} />
          ))}
        </PhotosContainer>
      </Container>
    </FormRow>
  );
};

export default PhotosInputRow;
