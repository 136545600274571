import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConversationWithDataDto } from '../../../services/Message/messageService.dto';
import { theme } from '../../../assets/styles/theme';
import { TextMain, TextSmall } from '../../../components/typography/Texts';
import ProfilePicture from '../../../components/image/ProfilePicture';
import { dateUtils } from '../../../utils/dateUtils';
import { useAuth } from '../../../providers/AuthProvider';

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  margin: 10px 5px 10px 0;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  padding: 8px;
  flex: 1;
  background-color: ${props => (props.isSelected ? theme.color.lighterGray : theme.color.white)};
  border-radius: 10px;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px 0 10px;
  flex: 1;
  color: ${theme.color.black};
`;

const SenderContainer = styled.div`
  display: flex;
  color: ${theme.color.black};
  align-items: center;
  flex-direction: row;
  overflow: hidden;
`;

const SenderName = styled.div<{ isRead: boolean }>`
  font-weight: ${props => (props.isRead ? 'normal' : 'bold')};
`;

const UnreadMsgWrapper = styled(TextSmall)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.1rem;
  border: ${theme.color.red} 2px solid;
  margin-left: 5px;
`;

const LastMsg = styled(TextSmall)`
  color: ${theme.color.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const LastMsgContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  max-width: 240px;
`;

const LastMsgDate = styled(TextSmall)`
  color: ${theme.color.black};
  margin-left: 5px;
  flex: 1;
  white-space: nowrap;
`;

const Dot = styled.div`
  display: flex;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: ${theme.color.red};
  margin-right: 5px;
`;

interface Props {
  conversation: ConversationWithDataDto;
  isSelected: boolean;
}

const ConversationListItem = (props: Props) => {
  const { t } = useTranslation('messages');
  const { currentUser } = useAuth();
  const [lastMsgTime, setLastMsgTime] = useState(
    dateUtils.formatTimeElapsed(new Date(props.conversation.lastMessageCreated))
  );

  useEffect(() => {
    setLastMsgTime(dateUtils.formatTimeElapsed(new Date(props.conversation.lastMessageCreated)));
    const interval = setInterval(
      () => setLastMsgTime(dateUtils.formatTimeElapsed(new Date(props.conversation.lastMessageCreated))),
      1000 * 60
    );
    return () => clearInterval(interval);
  }, [props.conversation.lastMessageCreated]);

  const senderImg = props.conversation.endUser?.imageUrl;
  const senderName = `${props.conversation.endUser?.firstName} ${props.conversation.endUser?.lastName}`;
  const supporterName = `${props.conversation.supporter?.firstName} ${props.conversation.supporter?.lastName}`;
  const unreadMessageCount = props.conversation.unreadMessageCount || 0;
  const isNotRead =
    props.conversation.unreadMessageCount &&
    props.conversation.unreadMessageCount > 0 &&
    props.conversation.supporter?.id === currentUser?.id;

  const conversationHeader = props.conversation.supporter ? senderName + ' / ' + supporterName : senderName;
  const conversationMessageInfo =
    props.conversation.searchMessages && props.conversation.searchMessages.length > 1
      ? `${props.conversation.searchMessages.length} ${t('matched')}`
      : props.conversation.lastMessageContent;

  return (
    <Container isSelected={props.isSelected}>
      <ProfilePicture imageUrl={senderImg} />
      <TextContainer>
        <SenderContainer>
          {isNotRead === true && <Dot />}
          <SenderName isRead={!isNotRead}>
            <TextMain>{conversationHeader}</TextMain>
          </SenderName>
          {isNotRead === true && <UnreadMsgWrapper>{unreadMessageCount}</UnreadMsgWrapper>}
        </SenderContainer>
        <LastMsgContainer>
          <LastMsg>{conversationMessageInfo}</LastMsg>
          {props.conversation.lastMessageContent && <LastMsgDate> | {lastMsgTime}</LastMsgDate>}
        </LastMsgContainer>
        {/*TODO add badge indicating conversation is resolved*/}
        {props.conversation.resolved && <TextSmall>[RESOLVED]</TextSmall>}
      </TextContainer>
    </Container>
  );
};

export default ConversationListItem;
