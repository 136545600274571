import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { prepareInitialFilter } from '../../../components/table/filterConverterUtils';
import { userService } from '../../../services/User/userService';
import { convertFiltersToFundraiserFilterDto } from '../utils/filterConverter';

type UrlParams = {
  userId?: string;
};

export const useFundraiserFilters = () => {
  const navigate = useNavigate();
  const { userId } = useParams<UrlParams>();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToFundraiserFilterDto(tableState);
    navigate(`/users/fundraisers/${filter.id || ''}`);
    return userService.fetchFundraisers(filter).then(response => response.data);
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(userId), [userId]);

  return { onTableFiltered, filterInitState };
};
