import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UnsafeTagDto } from '../../services/Image/imageService.dto';
import { imageService } from '../../services/Image/imageService';
import { SectionHeader } from '../../components/typography/Headers';
import { theme } from '../../assets/styles/theme';
import { TextMain } from '../../components/typography/Texts';
import RectangularButton from '../../components/buttons/RectangularButton';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import PageContainer from '../../components/containers/PageContainer';
import { useGlobalError } from '../../providers/GlobalErrorProvider';

const Container = styled.div`
  padding-left: 15px;
  padding-right: 0;
  flex-direction: column;
  display: flex;
  width: 100%;

  ${theme.mq.tablet} {
    padding-left: ${theme.navbarPadding}px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderLabel = styled(SectionHeader)`
  line-height: 40px;
`;

const ButtonGroup = styled.div`
  min-height: 40px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Button = styled(RectangularButton)<{ color: string }>`
  background-color: ${props => props.color};
  margin: 5px;
  min-width: 80px;
  font-weight: normal;
  height: 25px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;

const CategoryCard = styled.div`
  margin: 10px;
  padding: 10px;
  width: 280px;
`;

const TagRow = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  align-items: center;
`;

const Label = styled(TextMain)<{ isEdited: boolean }>`
  font-style: ${props => (props.isEdited ? 'italic' : 'normal')};
`;

interface UnsafeTag extends UnsafeTagDto {
  currentValue: boolean;
}

interface CategoryWithTags {
  name: string;
  unsafeTags: UnsafeTag[];
}

const Tags = () => {
  const { t } = useTranslation('manageContent');
  const [tags, setTags] = useState<UnsafeTag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { handleError } = useGlobalError();

  useEffect(() => {
    imageService
      .fetchTags()
      .then(response => {
        setTags(
          response.data.map(tag => {
            return { ...tag, currentValue: tag.igaUnsafe };
          })
        );
        setIsLoading(false);
      })
      .catch(e => {
        handleError(e);
        setIsLoading(false);
        setTags([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTag = (id: number) => {
    setTags(prevState => {
      return prevState.map(tag => {
        if (tag.id === id) return { ...tag, currentValue: !tag.currentValue };
        else return tag;
      });
    });
  };

  const handleCancel = () => {
    setTags(
      tags.map(tag => {
        return { ...tag, currentValue: tag.igaUnsafe };
      })
    );
  };

  const handleSave = () => {
    setIsLoading(true);
    const promises: Promise<any>[] = [];
    tags
      .filter(tag => tag.currentValue !== tag.igaUnsafe)
      .forEach(tag => {
        promises.push(imageService.updateTag(tag.id, tag.currentValue));
      });
    Promise.all(promises)
      .then(() => {
        setTags(
          tags.map(tag => {
            return { ...tag, igaUnsafe: tag.currentValue };
          })
        );
        setIsLoading(false);
      })
      .catch(e => {
        handleError(e);
        setIsLoading(false);
      });
  };

  const groupTagsIntoCategories = () => {
    const categoryWithTags: CategoryWithTags[] = [];
    tags.forEach(tag => {
      const category = categoryWithTags.filter(c => c.name === tag.category)[0];
      if (category) category.unsafeTags = [...category.unsafeTags, tag];
      else categoryWithTags.push({ name: tag.category, unsafeTags: [tag] });
    });
    return categoryWithTags;
  };

  const categories = groupTagsIntoCategories();
  const editedTags = tags.filter(tag => tag.igaUnsafe !== tag.currentValue);
  return (
    <PageContainer>
      <Container>
        <Header>
          <HeaderLabel>{t('tags.header')}</HeaderLabel>
          {editedTags.length > 0 && !isLoading && (
            <ButtonGroup>
              <Button label={t('common:save')} color={theme.color.green} onClick={handleSave} />
              <Button label={t('common:cancel')} color={theme.color.darkRed} onClick={handleCancel} />
            </ButtonGroup>
          )}
        </Header>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <TagsContainer>
            {categories.map(category => {
              return (
                <CategoryCard key={category.name}>
                  <TextMain>
                    <b>{category.name}</b>
                  </TextMain>
                  {category.unsafeTags.map(tag => {
                    const isEdited = tag.igaUnsafe !== tag.currentValue;
                    return (
                      <TagRow key={tag.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tag.currentValue}
                              onChange={() => toggleTag(tag.id)}
                              style={{ color: theme.color.red }}
                            />
                          }
                          label={<Label isEdited={isEdited}>{tag.name}</Label>}
                        />
                      </TagRow>
                    );
                  })}
                </CategoryCard>
              );
            })}
          </TagsContainer>
        )}
      </Container>
    </PageContainer>
  );
};

export default Tags;
