import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { orderService } from '../../../services/Order/orderService';
import { prepareInitialFilter } from '../../../components/table/filterConverterUtils';
import { convertFiltersToOrderFilterDto } from '../utils/filterConverter';

type UrlParams = {
  orderId: string;
};

export const useOrderFilters = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<UrlParams>();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToOrderFilterDto(tableState);
    navigate(`/orders/list/${filter.id || ''}`);
    return orderService.fetchOrders(filter).then(response => response.data);
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(orderId), [orderId]);

  return { onTableFiltered, filterInitState };
};
