import React from 'react';
import styled from 'styled-components';
import { MoonLoader } from 'react-spinners';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  size?: number;
  className?: string;
}

const LoadingIndicator = ({ size = 40, className }: Props) => {
  return (
    <Container className={className}>
      <MoonLoader size={size} color={theme.color.black} />
    </Container>
  );
};

export default LoadingIndicator;
