export const DEFAULT_COUNTRY_CODE = 'US';

export interface FailedShipmentDto {
  id: number;
  orderId: number;
  shipmentStatus: string;
  errorCode: string;
  errorMessage: string;
  status: string;
  buyerId: number;
  sellerId: number;
  retryTTL: number;
  shipFromCountry: string;
  shipFromCity: string;
  shipFromAddress1: string;
  shipFromAddress2: string;
  shipFromPostal: string;
  shipFromName: string;
  shipToCountry: string;
  shipToCity: string;
  shipToAddress1: string;
  shipToAddress2: string;
  shipToPostal: string;
  shipToName: string;
}

export interface FailedShipmentFilterDto {
  page: number;
  size: number;
  sort?: string;
}

export interface ShipmentReprocessingDto {
  id: number;
  orderId: number;
  shipmentStatus: string;
  tracking: string;
  errorCode: string;
  errorMessage: string;
}

export interface AggregatedShipmentCostsDto {
  carrier: string;
  carrierCost: number;
  customerShipmentPrice: number;
  customerShipmentPriceCurrency: string;
  period: string;
}

export interface ShipmentCostReportDto {
  carrier: string;
  customerShipmentPrice: number;
  customerShipmentPriceCurrency: string;
  deliveryStartDate: Date;
  id: number;
  trackingNumber: string;
  totalCharge: number;
  receiverAddress: ShipmentAddress;
  senderAddress: ShipmentAddress;
}

export interface ShipmentAddress {
  id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  state: string;
}

export interface ShipmentCostsFilterDto {
  page: number;
  size: number;
  sort?: string;
  reportPeriod: string;
  priceDifferenceIndicator?: boolean;
}

export interface GeocodingDto {
  zipCode: string;
  coordinates: CoordinatesDto;
  countryCode: string;
  city: string;
  state: StateDto;
}

export interface CoordinatesDto {
  latitude: number;
  longitude: number;
}

interface StateDto {
  name?: string;
  code?: string;
}
