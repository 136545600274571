import { apiService, apiUrl } from '../apiService';
import { dateUtils } from '../../utils/dateUtils';

export interface TempUrl {
  url: string;
  expirationTime: string;
  fields: any;
}

const GENERATE_URLS = `${apiUrl}/api/image/public/images/presign-post-urls`;

export enum UploadContext {
  MESSAGE,
  USER,
  CAMPAIGN,
  ITEM,
}

const TEMP_URL_FETCH_SIZE = 12;

class ImageUploadService {
  private static EXPIRATION_OFFSET = 5 * 1000;
  private TEMP_URL_CACHE: Map<UploadContext, TempUrl[]> = new Map();

  uploadImage(presignedUrl: string, img: File) {
    return apiService.put(presignedUrl, {}, { 'Content-Type': img.type }, img);
  }

  async uploadImageForTempUrl(tempUrl: TempUrl, file: File) {
    const formData = new FormData();
    for (const key in tempUrl.fields) formData.append(key, tempUrl.fields[key]);
    formData.append('file', file);
    formData.append('Content-Type', file.type);
    return apiService.post(tempUrl.url, {}, { 'Content-Type': 'multipart/form-data' }, formData);
  }

  getTempUrl = async (context: UploadContext): Promise<TempUrl> => {
    const cache = this.TEMP_URL_CACHE.get(context);
    const currentTime = new Date().getTime();

    // first try to find valid url in cache
    while (cache && cache.length) {
      const tempUrl = cache.shift();
      // expiration offset is used to skip url too close of expiration, to have time to start upload
      if (
        tempUrl &&
        currentTime <
          dateUtils.parseBackendDate(tempUrl.expirationTime).getTime() - ImageUploadService.EXPIRATION_OFFSET
      ) {
        return tempUrl;
      }
    }
    // if no valid temp url found, then ask for couple of new urls from backend
    const urls: TempUrl[] = await this.fetchNewTempUrls(context);
    // take one of temp urls and save rest for later
    const result = urls.shift();
    this.TEMP_URL_CACHE.set(context, urls);
    return result!;
  };

  fetchNewTempUrls = async (context: UploadContext): Promise<TempUrl[]> => {
    const data = { numberOfImages: TEMP_URL_FETCH_SIZE, context: UploadContext[context] };
    const response = await apiService.post(GENERATE_URLS, {}, {}, data);
    return response.data;
  };
}

const imageUploadService = new ImageUploadService();

export { ImageUploadService, imageUploadService };
