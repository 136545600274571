import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';
import DateInputWithLabel from '../../../components/inputs/DateInputWithLabel';
import RectangularButton from '../../../components/buttons/RectangularButton';
import { orderService } from '../../../services/Order/orderService';
import { dateUtils } from '../../../utils/dateUtils';
import { responseDownload } from '../../../utils/downloadUtils';
import GeneratePopover from './GeneratePopover';

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const GenerateButton = styled(RectangularButton)`
  height: 30px;
  margin: 5px;
`;

interface Props {
  onNewReport: () => void;
}

const WithdrawMoneyForm = (props: Props) => {
  const { t } = useTranslation('payments');
  const maxTo = dateUtils.getDateAgo(1);

  const [from, setFrom] = useState<Date | null>();
  const [to, setTo] = useState<Date | null>();
  const [toInvalid, setToInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generateButtonAnchor, setGenerateButtonAnchor] = useState<HTMLButtonElement | null>();
  const { handleError } = useGlobalError();

  useEffect(
    () => initStartDate(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initStartDate = () => {
    setLoading(true);
    orderService
      .fetchReportStartDate()
      .then(response => {
        setFrom(dateUtils.parseReportDate(response.data.reportStartDay));
        setTo(maxTo);
      })
      .catch(e => handleError(e, t('withdraw-money.date-fetch-error')))
      .finally(() => setLoading(false));
  };

  const generateReport = () => {
    setGenerateButtonAnchor(null);
    setGenerating(true);
    orderService
      .generateReport(from!, to!)
      .then(response => {
        responseDownload(response);
        props.onNewReport();
        initStartDate();
      })
      .catch(e => handleError(e, t('withdraw-money.generation-error')))
      .finally(() => setGenerating(false));
  };

  const showConfirmation = (element: HTMLButtonElement) => setGenerateButtonAnchor(element);

  const hideConformation = () => setGenerateButtonAnchor(null);

  return (
    <FormContainer>
      <DateInputWithLabel label={t('withdraw-money.form.from')} value={from} disabled={true} onChange={setFrom} />
      <DateInputWithLabel
        label={t('withdraw-money.form.to')}
        value={to}
        onChange={setTo}
        onError={setToInvalid}
        minDate={from!}
        maxDate={maxTo}
      />
      <GenerateButton
        label={t('withdraw-money.form.generate')}
        disabled={!from || !to || toInvalid || loading || generating}
        onClick={showConfirmation}
      />
      {generateButtonAnchor && (
        <GeneratePopover
          anchor={generateButtonAnchor}
          onClosePressed={hideConformation}
          onContinuePressed={generateReport}
        />
      )}
    </FormContainer>
  );
};

export default WithdrawMoneyForm;
