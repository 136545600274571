import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MessageDto } from '../../../../services/Message/messageService.dto';
import { dateUtils } from '../../../../utils/dateUtils';
import { useMessages } from '../../../../providers/messages/MessagesProvider';
import { TextMain } from '../../../../components/typography/Texts';
import { theme } from '../../../../assets/styles/theme';
import MessageBubble from './MessageBubble';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 10px 0 50px 0;
  overflow-y: auto;
`;

const ScrollToNewestContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 60px;
  right: 40px;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  background-color: ${theme.color.white};
  color: ${theme.color.red};
`;

const SCROLL_THRESHOLD = 5;

const MessageChat = () => {
  const { messages, fetchMessagesBefore, fetchMessagesAfter, conversation, selectedMessageId } = useMessages();
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldScrollToMessage, setShouldScrollToMessage] = useState(true);
  const [shouldRenderScrollToNewest, setShouldRenderScrollToNewest] = useState(false);

  useEffect(() => {
    if (containerRef.current && isScrollAtTheBottom()) containerRef.current.scrollTo(0, 0);
    if (selectedMessageId && shouldScrollToMessage) {
      const element = document.getElementById(selectedMessageId.toString());
      element?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [messages.length, selectedMessageId, shouldScrollToMessage]);

  useEffect(() => setShouldRenderScrollToNewest(false), [conversation?.id]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    setShouldScrollToMessage(false);
    const target = e.currentTarget;
    const diffToTop = target.scrollHeight + target.scrollTop - target.clientHeight;
    if (diffToTop < SCROLL_THRESHOLD) fetchMessagesBefore(false);
    if (Math.abs(target.scrollTop) < SCROLL_THRESHOLD) fetchMessagesAfter();
    checkIfScrollToNewestShouldBeDisplayed();
  };

  const handleScrollToNewestPress = () => {
    setShouldRenderScrollToNewest(false);
    fetchMessagesBefore(true, true);
  };

  const isScrollAtTheBottom = () => {
    const target = containerRef.current;
    return target && Math.abs(target.scrollTop) < target.clientHeight / 2;
  };

  const checkIfScrollToNewestShouldBeDisplayed = () => {
    const target = containerRef.current;
    if (target) {
      Math.abs(target.scrollTop) > target.clientHeight / 2
        ? setShouldRenderScrollToNewest(true)
        : setShouldRenderScrollToNewest(false);
    }
  };

  const isFirstMsgInDay = (msg: MessageDto, nextMsg: MessageDto) => {
    if (!nextMsg) return true;
    const dateNext = nextMsg.createdAt ? new Date(nextMsg.createdAt) : new Date();
    const date = msg.createdAt ? new Date(msg.createdAt) : new Date();
    return !dateUtils.areDatesOnTheSameDay(dateNext, date);
  };

  const renderScrollToNewestButton = () => {
    if (shouldRenderScrollToNewest) {
      return (
        <ScrollToNewestContainer onClick={handleScrollToNewestPress}>
          <TextMain>Scroll to newest</TextMain>
          <ArrowDownwardIcon />
        </ScrollToNewestContainer>
      );
    }
  };

  const renderMessages = () => {
    return messages.map((message, index, elements) => {
      const nextMsg = elements[index + 1];
      const firstMessageInDay = isFirstMsgInDay(message, nextMsg);
      const isMessageFromEndUser = conversation?.endUserId === message.authorId;
      return (
        <MessageBubble
          key={`${message.type}_${message.id}`}
          message={message}
          isMessageOwner={!isMessageFromEndUser}
          isFirstMsgInDay={firstMessageInDay}
        />
      );
    });
  };

  if (messages.length === 0) return <></>;
  return (
    <Container ref={containerRef} onScroll={handleScroll}>
      {renderMessages()}
      {renderScrollToNewestButton()}
    </Container>
  );
};

export default MessageChat;
