import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/table/DataTable';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { TableFilter } from '../../../components/table/FiltersPanel';
import DataTableWithFilters from '../../../components/table/DataTableWithFilters';
import { TransactionInfoDto } from '../../../services/Payment/paymentService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import CellWithNavigate from '../../../components/table/CellWithNavigate';
import CellWithPopover from '../../../components/table/CellWithPopover';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import { useTransactionFilters } from '../hooks/useTransactionFilters';
import { DEFAULT_CURRENCY, TRANSACTION_FILTER_MAX_AMOUNT, TRANSACTION_STATUS_OPTIONS } from '../utils/payment.types';
import TransactionErrorsPopover from './TransactionErrorsPopover';

const TransactionsTable = () => {
  const { t } = useTranslation('payments');
  const dataTableRef = useRef<DataTableRef>(null);
  const { onTableFiltered, filterInitState } = useTransactionFilters();

  const columns: Column<TransactionInfoDto>[] = [
    {
      id: 'transactionId',
      title: t('transactions.columns.transaction-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.transactionId,
    },
    {
      id: 'transactionDate',
      title: t('transactions.columns.transaction-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.transactionDate)),
    },
    {
      id: 'status',
      title: t('transactions.columns.status'),
      align: 'center',
      renderCell: data => data.transactionStatus,
    },
    {
      id: 'amount',
      title: t('transactions.columns.total-amount'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.amount,
    },
    {
      id: 'currency',
      title: t('transactions.columns.currency'),
      align: 'center',
      renderCell: data => data.currency,
    },
    {
      id: 'errors',
      title: t('transactions.columns.errors'),
      align: 'center',
      renderCell: data =>
        data.errors ? (
          <CellWithPopover popover={<TransactionErrorsPopover transactionId={data.transactionId} />}>
            {t('common:yes')}
          </CellWithPopover>
        ) : (
          t('common:no')
        ),
    },
    {
      id: 'method',
      title: t('transactions.columns.method-type'),
      align: 'center',
      renderCell: data => data.methodType,
    },
    {
      id: 'provider',
      title: t('transactions.columns.provider'),
      align: 'center',
      renderCell: data => data.provider,
    },
    {
      id: 'transactionType',
      title: t('transactions.columns.transaction-type'),
      align: 'center',
      renderCell: data => data.transactionType,
    },
    {
      id: 'payerId',
      title: t('transactions.columns.payer-id'),
      align: 'center',
      renderCell: data =>
        data.payerId && (
          <CellWithPopover popover={<UserDetailsCard userId={data.payerId} />}>{data.payerId}</CellWithPopover>
        ),
    },
    {
      id: 'orderId',
      title: t('transactions.columns.order-id'),
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/orders/list/${data.orderId}`}
          title={t('transactions.go-to-orders-tooltip', { id: data.orderId })}>
          {data.orderId}
        </CellWithNavigate>
      ),
    },
  ];

  const filters: TableFilter[] = [
    {
      type: 'numeric',
      name: 'id',
      label: t('transactions.filters.id'),
    },
    {
      type: 'numeric',
      name: 'orderId',
      label: t('transactions.filters.order-id'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: TRANSACTION_STATUS_OPTIONS,
      label: t('transactions.filters.status'),
    },
    {
      type: 'multi-choice',
      name: 'currency',
      options: [DEFAULT_CURRENCY],
      label: t('transactions.filters.currency'),
    },
    {
      type: 'numeric',
      name: 'payerId',
      label: t('transactions.filters.payer-id'),
    },
    {
      type: 'date',
      name: 'date',
      label: t('transactions.filters.date'),
    },
    {
      type: 'checkbox',
      name: 'errors',
      label: t('transactions.filters.errors'),
      checked: false,
    },
    {
      type: 'range',
      name: 'amount',
      label: t('transactions.filters.amount'),
      max: TRANSACTION_FILTER_MAX_AMOUNT,
    },
  ];

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        hideApplyButton={true}
        refreshOnFiltersChange={true}
        onTableStateChanged={onTableFiltered}
        filterInitState={filterInitState}
      />
    </>
  );
};

export default TransactionsTable;
