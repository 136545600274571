import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ItemDetailsCard from '../components/ItemDetailsCard';
import { usePopup } from '../../../providers/PopupProvider';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { itemService } from '../../../services/Item/itemService';
import { prepareInitialFilter } from '../../../components/table/filterConverterUtils';
import { convertFiltersToItemFilterDto } from '../utils/converters';

type UrlParams = {
  itemId?: string;
};

export const useItemFilters = () => {
  const navigate = useNavigate();
  const { itemId } = useParams<UrlParams>();
  const { showPopover } = usePopup();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToItemFilterDto(tableState);
    navigate(`/items/list/${filter.id || ''}`);
    return itemService.fetchItems(filter).then(response => {
      if (filter.id && response.data.content?.length === 1) {
        const itemInfo = response.data.content[0];
        showPopover(<ItemDetailsCard itemInfo={itemInfo} />);
      }
      return response.data;
    });
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(itemId), [itemId]);

  return { onTableFiltered, filterInitState };
};
