import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/svg/close.svg';
import { ConfirmButton, CloseButton } from '../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 320px;
`;

interface Props {
  anchor: HTMLDivElement | null;
  userData: { firstName: string; lastName: string };
  onUnblockPressed: () => void;
  onClosePressed: () => void;
}

const UnblockUserPopover = (props: Props) => {
  const { t } = useTranslation('users');
  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>
          {t('unblock-label')} <b>{`${props.userData.firstName} ${props.userData.lastName}`}</b>
        </FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={props.onClosePressed} />
        <ConfirmButton label={t('unblock-btn')} onClick={props.onUnblockPressed} />
      </FormContainer>
    </Popover>
  );
};

export default UnblockUserPopover;
