import i18n from 'i18next';

const DEFAULT_CURRENCY_SIGN = '$';

export const formatPrice = (price?: number, isNegotiable?: boolean) => {
  let priceFormatted = `${DEFAULT_CURRENCY_SIGN}${price || 0}`;
  if (isNegotiable) {
    priceFormatted = `${priceFormatted} (${i18n.t('common:negotiable')})`;
  }
  return priceFormatted;
};

export const formatMoneyCollected = (fundsRaised: number, moneyGoal?: number) => {
  let txt = `${DEFAULT_CURRENCY_SIGN}${fundsRaised || 0}`;
  if (moneyGoal) {
    const completionPercent = (fundsRaised / moneyGoal) * 100;
    const completionPercentRounded = Math.round((completionPercent + Number.EPSILON) * 100) / 100;
    txt = `${txt} ${i18n.t('common:of')} ${moneyGoal} (${completionPercentRounded}%)`;
  }
  return txt;
};
