import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImagePreview from '../../../components/image/ImagePreview';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import {
  CardContainer,
  CardCreatedInfo,
  CardHeader,
  CardTitle,
  Column,
  Row,
} from '../../../components/cards/CardCommons';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import CardParamInfo from '../../../components/cards/CardParamInfo';
import { formatLocation } from '../../../utils/locationFormatter';
import { useUserDetails } from '../hooks/useUserDetails';

const UserCardContainer = styled(CardContainer)`
  width: 600px;
  min-height: 300px;
`;

interface Props {
  userId?: number;
  userInfo?: UserDetailsDto;
}

const UserDetailsCard = ({ userId, userInfo }: Props) => {
  const { t } = useTranslation('users');

  const { isLoading, user } = useUserDetails(userId, userInfo);

  if (!user || isLoading)
    return (
      <CardContainer>
        <LoadingIndicator />
      </CardContainer>
    );

  return (
    <UserCardContainer>
      <CardHeader>{t('user-details.header', { id: user.id })}</CardHeader>
      <Row>
        <ImagePreview imageUrls={user.profileImageUrl ? [user.profileImageUrl] : undefined} />
        <Column>
          <CardTitle>
            {user.firstName} {user.lastName}
          </CardTitle>
          <CardCreatedInfo>{`${dateUtils.formatDateDate(new Date(user.created))}`}</CardCreatedInfo>
          <CardParamInfo label={t('user-details.email-label')} value={user.email} />
          {user.city && (
            <CardParamInfo
              label={t('user-details.location-label')}
              value={formatLocation(user.city, undefined, user.zip)}
            />
          )}
          <CardParamInfo label={t('user-details.status-label')} value={user.userStatus} />
          <CardParamInfo
            label={t('user-details.verified-label')}
            value={user.fundraiserVerified ? t('common:yes') : t('common:no')}
          />
        </Column>
      </Row>
    </UserCardContainer>
  );
};

export default UserDetailsCard;
