import React from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { theme } from '../../assets/styles/theme';
import { TextSmall } from '../typography/Texts';

const Container = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  background-color: ${theme.color.lighterGray};
  font-size: ${theme.font.size.xs};
  font-family: ${theme.font.family.main};
  outline: 0;
  min-width: 150px;
  height: 40px;
  text-align: left;

  & .MuiSelect-select:focus {
    background: 0;
  }

  & .MuiSelect-icon {
    top: unset;
  }

  & :focus {
    border: unset;
  }

  & legend {
    font-size: 1.4rem;
  }
`;

interface Props {
  label: string;
  onChange: (value?: string) => void;
  options?: string[];
  value?: string;
  placeholder?: string;
  minWidth?: number;
  optionsMap?: Map<any, string>;
  disabled?: boolean;
  hideEmptyOption?: boolean;
}

const DropdownWithLabel = (props: Props) => {
  const placeholder = props.placeholder || 'All';

  const handleChange = (value: string) => {
    if (value === placeholder) props.onChange(undefined);
    else props.onChange(value);
  };

  const menuItems = () => {
    if (props.options) {
      return props.options.map(option => (
        <MenuItem key={option} value={option}>
          <TextSmall>{option}</TextSmall>
        </MenuItem>
      ));
    } else if (props.optionsMap) {
      return Array.from(props.optionsMap.keys()).map(key => (
        <MenuItem key={key} value={key}>
          <TextSmall>{props.optionsMap?.get(key)}</TextSmall>
        </MenuItem>
      ));
    }
  };

  return (
    <Container>
      <FormControl variant='outlined'>
        <InputLabel style={{ fontSize: '1.8rem' }} id='label'>
          {props.label}
        </InputLabel>
        <StyledSelect
          style={{ minWidth: props.minWidth }}
          labelId={'label'}
          disabled={props.disabled}
          onChange={e => handleChange(e.target.value as string)}
          value={props.value || placeholder}
          label={props.label}>
          {!props.hideEmptyOption && (
            <MenuItem value={placeholder}>
              <TextSmall>
                <i>{placeholder}</i>
              </TextSmall>
            </MenuItem>
          )}
          {menuItems()}
        </StyledSelect>
      </FormControl>
    </Container>
  );
};

export default DropdownWithLabel;
