import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RectangularButton from '../../../../components/buttons/RectangularButton';
import { ItemContext, ItemContextProps, useItemForm } from '../../hooks/ItemContext';
import { theme } from '../../../../assets/styles/theme';
import { TextMain } from '../../../../components/typography/Texts';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { FormContainer, FormHeader, FormTable } from './Containers';
import UserInputRow from './UserInputRow';
import TypeInputRow from './TypeInputRow';
import SellingMethodInputRow from './SellingMethodInputRow';
import ConditionInputRow from './ConditionInputRow';
import PriceInputRow from './PriceInputRow';
import PhotosInputRow from './PhotosInputRow';
import CategoryInputRow from './CategoryInputRow';
import DeliveryInputRow from './DeliveryInputRow';
import LocationInputRow from './LocationInputRow';
import QuantityInputRow from './QuantityInputRow';
import TitleInputRow from './TitleInputRow';
import DescriptionInputRow from './DescriptionInputRow';
import CampaignInputRow from './CampaignInputRow';
import AuctionEndDateInputRow from './AuctionEndDateInputRow';

const TableWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  margin-bottom: 45px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SaveButton = styled(RectangularButton)`
  width: 50px;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px;
`;

const ErrorMessages = styled.div`
  width: 480px;
  min-height: 40px;
  max-height: 800px;
  border-radius: 10px;
  align-items: flex-start;
  border: 2px solid ${theme.color.darkGray};
  background-color: ${theme.color.white};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  padding: 10px;

  ${SaveButton}:hover + & {
    display: flex;
    flex-direction: column;
  }
`;

const ErrorMessage = styled(TextMain)`
  color: ${theme.color.red};
`;

const ItemForm = () => {
  const { t } = useTranslation('items');
  const { itemId, errorMessages, isLoading, saveItem } = useItemForm();

  return (
    <FormContainer>
      <FormHeader>{!itemId ? t('add-item.header-add') : t('add-item.header-edit', { itemId })}</FormHeader>
      {isLoading && (
        <LoadingOverlay>
          <LoadingIndicator />{' '}
        </LoadingOverlay>
      )}
      <TableWrapper>
        <FormTable>
          <tbody>
            <UserInputRow />
            <TypeInputRow />
            <SellingMethodInputRow />
            <TitleInputRow />
            <DescriptionInputRow />
            <ConditionInputRow />
            <CategoryInputRow />
            <DeliveryInputRow />
            <LocationInputRow />
            <PriceInputRow />
            <AuctionEndDateInputRow />
            <QuantityInputRow />
            <CampaignInputRow />
            <PhotosInputRow />
          </tbody>
        </FormTable>
      </TableWrapper>

      <SaveButton label={t('common:save')} onClick={saveItem} disabled={errorMessages.length > 0} />
      {errorMessages.length > 0 && (
        <ErrorMessages>
          {errorMessages.map(msg => (
            <ErrorMessage key={msg}>{msg}</ErrorMessage>
          ))}
        </ErrorMessages>
      )}
    </FormContainer>
  );
};

const ItemFormWithContext = ({ itemId, onItemSaved }: ItemContextProps) => {
  return (
    <ItemContext itemId={itemId} onItemSaved={onItemSaved}>
      <ItemForm />
    </ItemContext>
  );
};

export default ItemFormWithContext;
