import React from 'react';
import Navigation from './navigation/Navigation';
import { AuthProvider } from './providers/AuthProvider';
import GlobalStyle from './assets/styles/GlobalStyle';
import { GlobalDataProvider } from './providers/GlobalDataProvider';
import { GlobalErrorProvider } from './providers/GlobalErrorProvider';
import { PopupProvider } from './providers/PopupProvider';

function App() {
  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <GlobalErrorProvider>
          <GlobalDataProvider>
            <PopupProvider>
              <Navigation />
            </PopupProvider>
          </GlobalDataProvider>
        </GlobalErrorProvider>
      </AuthProvider>
    </>
  );
}

export default App;
