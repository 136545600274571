import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import imageNotFound from '../../assets/svg/image-not-found.svg';
import { ImageContainer, LargeImage, MiniImageContainer, MiniImage } from '../cards/CardCommons';
import { convertToThumbnail300, convertToThumbnail600 } from '../../utils/thumbnails';

const ImageNotFound = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 12px;
  display: flex;
`;

interface Props {
  imageUrls?: string[];
}

const ImagePreview = (props: Props) => {
  const [largeImage, setLargeImage] = useState<string>();

  useEffect(() => {
    if (props?.imageUrls) setLargeImage(props.imageUrls[0]);
  }, [props.imageUrls]);

  return (
    <ImageContainer>
      {largeImage ? (
        <LargeImage src={convertToThumbnail600(largeImage)} alt={'msg-img'} />
      ) : (
        <ImageNotFound src={imageNotFound} alt={'msg-img'} />
      )}
      {props?.imageUrls && props.imageUrls.length > 1 && (
        <MiniImageContainer>
          {props.imageUrls.map(img => {
            return (
              <MiniImage
                key={props.imageUrls?.indexOf(img)}
                src={convertToThumbnail300(img)}
                alt={'msg-img'}
                onClick={() => setLargeImage(img)}
              />
            );
          })}
        </MiniImageContainer>
      )}
    </ImageContainer>
  );
};

export default ImagePreview;
