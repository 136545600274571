import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FormHeader } from '../typography/PopoverTypography';
import { ConfirmButton } from '../buttons/popoverButtons';
import InputWithLabel from '../inputs/InputWithLabel';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 320px;
`;

interface Props {
  value: number;
  onConfirmPress: (value: number) => void;
}

const UpdateSortingBoostForm = ({ value, onConfirmPress }: Props) => {
  const { t } = useTranslation();
  const [adminSortingBoost, setAdminSortingBoost] = useState(value.toString());

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      onConfirmPress(parseInt(adminSortingBoost));
    }
  };

  return (
    <FormContainer onKeyDown={handleKeyPress}>
      <FormHeader>{t('sorting-boost.header')}</FormHeader>
      <InputWithLabel
        label={''}
        autoFocus={true}
        onChange={setAdminSortingBoost}
        value={adminSortingBoost}
        minWidth={180}
      />
      <ConfirmButton
        label={t('sorting-boost.confirm-btn')}
        onClick={() => onConfirmPress(parseInt(adminSortingBoost))}
      />
    </FormContainer>
  );
};

export default UpdateSortingBoostForm;
