import { TableFilterStateDto } from './DataTable';

export const parseMultiChoice = (filter: TableFilterStateDto<Map<string, any>>, key: string) => {
  if (!filter.filtersMap?.get(key)) return undefined;
  if ((filter.filtersMap?.get(key) as string[]).length === 0) return undefined;
  return filter.filtersMap?.get(key).join(',');
};

export const prepareInitialFilter = (idFromUrl?: string) => {
  const id = idFromUrl ? parseInt(idFromUrl) : undefined;
  if (!!id && Number.isInteger(id)) {
    return new Map([['id', id]]);
  } else {
    return new Map();
  }
};
