import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_CURRENCY,
  DEFAULT_ITEM_LOCATION,
  ItemCategoryDto,
  ItemConditionDto,
  ItemLocationInfoDto,
  ItemType,
  SellingMethod,
} from '../../../services/Item/itemService.dto';
import { UserDetailsDto, UserDto } from '../../../services/User/userService.dto';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { CampaignAdminDto, CampaignStatus } from '../../../services/Campaign/campaignService.dto';
import { convertFormDataToEditItemDto, convertFormDataToNewItemDto } from '../utils/converters';
import { itemService } from '../../../services/Item/itemService';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';
import { validateItemForm } from '../utils/validators';
import { dateUtils } from '../../../utils/dateUtils';
import { useUserDetails } from '../../users/hooks/useUserDetails';
import { useCampaignDetails } from '../../campaigns/hooks/useCampaignDetails';
import { ImageDto, useItemImages } from './useItemImages';
import { useItemDetails } from './useItemDetails';

export interface ItemFormData {
  id?: number;
  user?: UserDto;
  type?: ItemType;
  title?: string;
  description?: string;
  sellingMethod?: SellingMethod;
  condition?: ItemConditionDto;
  category?: ItemCategoryDto;
  price?: number;
  currency: string;
  negotiable: boolean;
  auctionEndDate?: Date;
  quantity?: number;
  canLocalPickupDelivery: boolean;
  canNationwideShipping: boolean;
  itemLocation: ItemLocationInfoDto;
  weightUpTo?: number;
  photos: ImageDto[];
  campaign?: CampaignAdminDto;
}

interface ItemContextType {
  formData: ItemFormData;
  itemId?: number;
  setUser: (user?: UserDetailsDto) => void;
  setType: (type?: ItemType) => void;
  setSellingMethod: (sellingMethod?: SellingMethod) => void;
  setTitle: (title?: string) => void;
  setDescription: (description?: string) => void;
  setCondition: (condition?: ItemConditionDto) => void;
  setCategory: (category?: ItemCategoryDto) => void;
  setPrice: (price?: number) => void;
  setCurrency: (currency: string) => void;
  setNegotiable: (negotiable: boolean) => void;
  setAuctionEndDate: (endDate?: Date) => void;
  setQuantity: (quantity?: number) => void;
  setCanLocalPickupDelivery: (canLocalPickupDelivery: boolean) => void;
  setCanNationwideShipping: (canNationwideShipping: boolean) => void;
  setItemLocation: (itemLocation: ItemLocationInfoDto) => void;
  setWeightUpTo: (weightUpTo?: number) => void;
  setCampaign: (campaign?: CampaignAdminDto) => void;
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  errorMessages: string[];
  isLoading: boolean;
  saveItem: () => void;
}

const context = React.createContext<ItemContextType | null>(null);

export interface ItemContextProps extends PropsWithChildren {
  itemId?: number;
  onItemSaved: () => void;
}

const ItemContext = ({ itemId, onItemSaved, children }: ItemContextProps) => {
  const { itemConditions, itemCategories } = useGlobalData();
  const { errorMessage } = useGlobalError();

  const { item, auction } = useItemDetails(itemId);
  const { user: currentUser } = useUserDetails(item?.ownerId);
  const { campaign: currentCampaign } = useCampaignDetails(item?.supportedCampaignId);

  const [isLoading, setIsLoading] = useState(!!itemId && !item);

  const [user, setUser] = useState<UserDetailsDto>();
  const [type, setType] = useState<ItemType | undefined>('REGULAR');
  const [sellingMethod, setSellingMethod] = useState<SellingMethod | undefined>('STANDARD');
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [condition, setCondition] = useState<ItemConditionDto | undefined>(itemConditions[0]);
  const [category, setCategory] = useState<ItemCategoryDto | undefined>(
    itemCategories.filter(category => category.itemType === type)[0]
  );
  const [price, setPrice] = useState<number | undefined>();
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [negotiable, setNegotiable] = useState(true);
  const [auctionEndDate, setAuctionEndDate] = useState<Date | undefined>(dateUtils.dateWithDaysOffset(new Date(), 1));
  const [quantity, setQuantity] = useState<number | undefined>();
  const [canLocalPickupDelivery, setCanLocalPickupDelivery] = useState(true);
  const [canNationwideShipping, setCanNationwideShipping] = useState(true);
  const [itemLocation, setItemLocation] = useState<ItemLocationInfoDto>(DEFAULT_ITEM_LOCATION);
  const [weightUpTo, setWeightUpTo] = useState<number | undefined>(0.5);
  const [campaign, setCampaign] = useState<CampaignAdminDto>();

  const { photos, setPhotoUrls, addPhoto, deletePhoto } = useItemImages(item?.imageUrlList);

  useEffect(() => {
    if (item) {
      setType(item.itemType);
      setSellingMethod(item.sellingMethod);
      setTitle(item.title);
      setDescription(item.description);
      setCondition(itemConditions.find(condition => condition.code === item.condition));
      setCategory(itemCategories.find(category => category.code === item.category));
      setPrice(item.price);
      setCurrency(item.currency);
      setNegotiable(item.canAcceptLoHiOffers);
      setQuantity(item.unitsQuantity);
      setCanLocalPickupDelivery(item.canLocalPickUpDelivery);
      setCanNationwideShipping(item.canNationwideShipping);
      setItemLocation(item.itemLocation);
      setWeightUpTo(item.weightUpTo);
      setPhotoUrls(item.imageUrlList);
    }
  }, [item]);

  useEffect(() => {
    if (auction) {
      setAuctionEndDate(dateUtils.parseBackendDate(auction.endDateTime));
    }
  }, [auction]);

  useEffect(() => setUser(currentUser), [currentUser]);
  useEffect(() => {
    if (currentCampaign) {
      const campaignAdminDto: CampaignAdminDto = {
        id: currentCampaign.id,
        name: currentCampaign.name,
        description: currentCampaign.description,
        ownerId: currentCampaign.ownerId,
        categories: currentCampaign.categories,
        status: currentCampaign.status as CampaignStatus,
        moneyCollected: 0,
        adminSortingBoost: 0,
        moneyCollectedWithBoost: 0,
      };
      setCampaign(campaignAdminDto);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (itemId) {
      setIsLoading(!item || !currentUser || !currentCampaign);
    }
  }, [itemId, item, currentUser, currentCampaign]);

  const saveItem = () => {
    setIsLoading(true);
    if (!itemId) addItem();
    else editItem(itemId);
  };

  const addItem = () => {
    const newItemDto = convertFormDataToNewItemDto(formData);
    if (!formData.user) return;
    itemService
      .addItemByEmployee(newItemDto, formData.user.id)
      .then(() => onItemSaved())
      .catch(e => errorMessage(e?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  const editItem = (itemId: number) => {
    const editItemDto = convertFormDataToEditItemDto(formData, itemId);
    itemService
      .editItemByEmployee(editItemDto)
      .then(() => onItemSaved())
      .catch(e => errorMessage(e?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  const formData: ItemFormData = {
    id: itemId,
    user,
    type,
    sellingMethod,
    title,
    description,
    condition,
    category,
    price,
    currency,
    negotiable,
    auctionEndDate,
    quantity,
    canLocalPickupDelivery,
    canNationwideShipping,
    itemLocation,
    photos,
    weightUpTo,
    campaign,
  };

  const errorMessages = useMemo(() => validateItemForm(formData, item), [formData, item]);

  return (
    <context.Provider
      value={{
        itemId,
        formData,
        setUser,
        setType,
        setSellingMethod,
        setTitle,
        setDescription,
        setCondition,
        setCategory,
        setPrice,
        setCurrency,
        setNegotiable,
        setAuctionEndDate,
        setQuantity,
        setCanLocalPickupDelivery,
        setCanNationwideShipping,
        setItemLocation,
        setWeightUpTo,
        setCampaign,
        addPhoto,
        deletePhoto,
        errorMessages,
        isLoading,
        saveItem,
      }}>
      {children}
    </context.Provider>
  );
};

const useItemForm = () => {
  const itemContext = useContext(context);
  if (itemContext == null) {
    throw new Error('useItemForm() called outside of an ItemContext?');
  }
  return itemContext;
};

export { ItemContext, useItemForm };
