import React from 'react';
import styled from 'styled-components';
import { TextSmall } from '../../../../components/typography/Texts';
import { theme } from '../../../../assets/styles/theme';
import { CampaignAdminDto } from '../../../../services/Campaign/campaignService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${theme.color.lightGray};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  campaign: CampaignAdminDto;
  onClick: () => void;
}

const CampaignsListItem = ({ campaign, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <InfoContainer>
        <TextSmall>
          [#{campaign.id}] {campaign.name}
        </TextSmall>
      </InfoContainer>
    </Container>
  );
};

export default CampaignsListItem;
