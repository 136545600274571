import i18n from 'i18next';

export const formatLocation = (city: string, state?: string, zipCode?: string) => {
  let location = city;
  if (state || zipCode) location = `${location},`;
  if (state) location = `${location} ${state}`;
  if (zipCode) location = `${location} ${zipCode}`;
  return location;
};

export const formatDelivery = (canNationwideShipping?: boolean, canLocalPickUpDelivery?: boolean) => {
  let delivery: string[] = [];
  if (canNationwideShipping) delivery = [i18n.t('common:delivery-shipping')];
  if (canLocalPickUpDelivery) delivery = [...delivery, i18n.t('common:delivery-pickup')];
  return delivery.join(', ');
};
