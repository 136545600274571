import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { prepareInitialFilter } from '../../../components/table/filterConverterUtils';
import { userService } from '../../../services/User/userService';
import { usePopup } from '../../../providers/PopupProvider';
import UserDetailsCard from '../components/UserDetailsCard';
import { convertFiltersToUserFilterDto } from '../utils/filterConverter';

type UrlParams = {
  userId?: string;
};

export const useUserFilters = () => {
  const navigate = useNavigate();
  const { userId } = useParams<UrlParams>();
  const { showPopover } = usePopup();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToUserFilterDto(tableState);
    navigate(`/users/find-user/${filter.id || ''}`);
    return userService.fetchUsers(filter).then(response => {
      if (filter.id && response.data.content?.length === 1) {
        const userInfo = response.data.content[0];
        showPopover(<UserDetailsCard userInfo={userInfo} />);
      }
      return response.data;
    });
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(userId), [userId]);

  return { onTableFiltered, filterInitState };
};
