import { TableFilterStateDto } from '../../../components/table/DataTable';
import { OrderFilterDto, RefundRequestFilterDto } from '../../../services/Order/orderService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import { parseMultiChoice } from '../../../components/table/filterConverterUtils';

export const convertFiltersToOrderFilterDto = (filter: TableFilterStateDto<Map<string, any>>): OrderFilterDto => {
  const dateParsed = filter.filtersMap?.get('date') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('date')));

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    id: filter.filtersMap?.get('id'),
    creationDateFrom: dateParsed,
    creationDateTo: dateParsed,
    orderType: parseMultiChoice(filter, 'type'),
    status: parseMultiChoice(filter, 'status'),
    basePriceFrom: filter.filtersMap?.get('basePriceFrom'),
    basePriceTo: filter.filtersMap?.get('basePriceTo'),
    campaignId: filter.filtersMap?.get('campaignId'),
    itemId: filter.filtersMap?.get('itemId'),
    buyerId: filter.filtersMap?.get('buyerId'),
  };
};

export const convertFiltersToRefundRequestFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>
): RefundRequestFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    orderId: filter.filtersMap?.get('orderId'),
    status: parseMultiChoice(filter, 'status'),
  };
};
