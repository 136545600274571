import React from 'react';
import styled from 'styled-components';
import { ConversationWithDataDto } from '../../../../services/Message/messageService.dto';
import ProfilePicture from '../../../../components/image/ProfilePicture';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { SectionHeader } from '../../../../components/typography/Headers';
import closeIcon from '../../../../assets/svg/close.svg';
import { theme } from '../../../../assets/styles/theme';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;

const InterlocutorName = styled(SectionHeader)`
  margin-left: 10px;
  margin-right: 20px;
`;

const CloseButton = styled.img`
  position: absolute;
  right: -${theme.paddingStandard}px;
  cursor: pointer;
  padding: ${theme.paddingStandard}px;
`;

interface Props {
  conversation?: ConversationWithDataDto;
  onCloseConversation: () => void;
}

const MessageThreadHeader = (props: Props) => {
  if (!props.conversation) return <LoadingIndicator />;
  const senderImg = props.conversation.endUser?.imageUrl;
  const endUserName = `${props.conversation.endUser?.firstName} ${props.conversation.endUser?.lastName}`;
  const supporterName = `${props.conversation.supporter?.firstName} ${props.conversation.supporter?.lastName}`;
  const interlocutors = props.conversation.supporter ? endUserName + ' / ' + supporterName : endUserName;
  return (
    <Container>
      <ProfilePicture imageUrl={senderImg} />
      <InterlocutorName>{interlocutors}</InterlocutorName>
      <CloseButton src={closeIcon} alt={'close'} onClick={props.onCloseConversation} />
    </Container>
  );
};

export default MessageThreadHeader;
