import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import { useItemForm } from '../../hooks/ItemContext';
import { useGlobalData } from '../../../../providers/GlobalDataProvider';
import FormRow from './FormRow';

const ConditionInputRow = () => {
  const { t } = useTranslation('items');
  const { itemConditions } = useGlobalData();
  const { formData, setCondition } = useItemForm();
  if (formData.type === 'SERVICE') return null;
  return (
    <FormRow label={t('add-item.condition')}>
      <DropdownWithLabel
        value={formData.condition?.displayName || itemConditions[0].displayName}
        options={[...itemConditions.map(condition => condition.displayName)]}
        label={''}
        hideEmptyOption={true}
        onChange={value => setCondition(itemConditions.find(condition => condition.displayName === value))}
        minWidth={500}
      />
    </FormRow>
  );
};

export default ConditionInputRow;
