import { TableFilterStateDto } from '../../../components/table/DataTable';
import { CampaignCategoryDto, CampaignFilterDto } from '../../../services/Campaign/campaignService.dto';

export const convertFiltersToCampaignFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>,
  categories: CampaignCategoryDto[]
): CampaignFilterDto => {
  const categoryValue = filter.filtersMap?.get('category');
  const categoryKey = categories.find(category => category.displayName === categoryValue)?.code;

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    id: filter.filtersMap?.get('id'),
    name: filter.filtersMap?.get('name'),
    description: filter.filtersMap?.get('description'),
    category: categoryKey,
    ownerId: filter.filtersMap?.get('ownerId'),
  };
};
