import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/svg/close.svg';
import { CloseButton, ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader, FormText } from '../../../components/typography/PopoverTypography';
import { FeatureFlagResponse } from '../../../services/FrontendMeta/frontendMetaService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  open: boolean;
  featureFlag: FeatureFlagResponse;
  onClosePressed: () => void;
  onConfirmPress: (enabled: boolean) => void;
}

const FeatureFlagsUpdateDialog = ({ open, featureFlag, onClosePressed, onConfirmPress }: Props) => {
  const { t } = useTranslation('manageContent');

  return (
    <Dialog open={open} onClose={onClosePressed}>
      <FormContainer>
        <FormHeader>
          {t('feature-flags.update-dialog-title')} <b>{`${featureFlag.name}`} </b>
        </FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />
        <FormText>{t('feature-flags.update-dialog-description')}</FormText>

        <ConfirmButton
          label={t('feature-flags.update-dialog-confirm')}
          onClick={() => onConfirmPress(!featureFlag.enabled)}
        />
      </FormContainer>
    </Dialog>
  );
};

export default FeatureFlagsUpdateDialog;
