import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import { SELLING_METHODS, SellingMethod } from '../../../../services/Item/itemService.dto';
import { useItemForm } from '../../hooks/ItemContext';
import FormRow from './FormRow';

const SellingMethodInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setSellingMethod } = useItemForm();
  return (
    <FormRow label={t('add-item.selling-method')}>
      <DropdownWithLabel
        value={formData.sellingMethod}
        options={[...SELLING_METHODS]}
        label={''}
        onChange={value => setSellingMethod(value as SellingMethod)}
        hideEmptyOption={true}
        minWidth={500}
        disabled={!!formData.id}
      />
    </FormRow>
  );
};

export default SellingMethodInputRow;
