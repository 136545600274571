import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import { reportService } from '../../services/Report/reportService';
import { dateUtils } from '../../utils/dateUtils';
import { TableFilter } from '../../components/table/FiltersPanel';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import UserBlockReminderPopover from '../users/components/UserBlockReminderPopover';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import PageContainer from '../../components/containers/PageContainer';
import { Column, TableFilterStateDto } from '../../components/table/DataTable';
import { ReportDto } from '../../services/Report/reportService.dto';
import { UserPublicDto } from '../../services/User/userService.dto';
import BlockUserPopover from '../users/components/BlockUserPopover';
import { userService } from '../../services/User/userService';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import { DataTableRef } from '../../components/table/dataTableRef';
import { usePopup } from '../../providers/PopupProvider';
import UserDetailsCard from '../users/components/UserDetailsCard';
import Reporters from './components/Reporters';
import ReportActions from './components/ReportActions';
import ReportActionPopover from './components/ReportActionPopover';
import ObjectIdCell from './components/ObjectIdCell';
import { ActionType, ObjectType } from './utils/dictionary';
import { convertFiltersToReportFilterDto } from './utils/reportConverters';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;
const PointerContainer = styled.div`
  cursor: pointer;
  min-height: 15px;
`;

const UnsafeContentReports = () => {
  const { t } = useTranslation('manageContent');
  const { blockadeReasons } = useGlobalData();
  const { showPopover } = usePopup();

  const [reportActionAnchor, setReportActionAnchor] = useState<SVGSVGElement | HTMLDivElement>();
  const [reminderAnchor, setReminderAnchor] = useState<SVGSVGElement | HTMLDivElement>();
  const [blockAnchor, setBlockAnchor] = useState<SVGSVGElement | HTMLDivElement>();
  const [selectedReport, setSelectedReport] = useState<ReportDto>();
  const [selectedAction, setSelectedAction] = useState<ActionType>();
  const [reminderUserId, setReminderUserId] = useState<number>();
  const dataTableRef = useRef<DataTableRef>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserPublicDto>();
  const { handleError } = useGlobalError();

  const checkboxesState = [
    { name: 'visible', checked: true },
    { name: 'hidden', checked: true },
  ];

  const columns: Column<ReportDto>[] = [
    {
      id: 'reason',
      title: t('unsafe-content.columns.reason'),
      maxWidth: 300,
      renderCell: data => data.reasons?.join('; '),
    },
    {
      id: 'objectType',
      title: t('unsafe-content.columns.object-type'),
      isSortable: true,
      renderCell: data => data.objectType,
    },
    {
      id: 'objectId',
      title: t('unsafe-content.columns.object-id'),
      maxWidth: 70,
      renderCell: data => <ObjectIdCell reportDto={data} />,
    },
    {
      id: 'firstReportDate',
      title: t('unsafe-content.columns.first-reported'),
      isSortable: true,
      renderCell: data => dateUtils.formatDate(new Date(data.firstReportDate)),
    },
    {
      id: 'lastReportDate',
      title: t('unsafe-content.columns.last-reported'),
      isSortable: true,
      renderCell: data => dateUtils.formatDate(new Date(data.lastReportDate)),
    },
    {
      id: 'reportsCount',
      title: t('unsafe-content.columns.reports-number'),
      maxWidth: 70,
      isSortable: true,
      renderCell: data => data.reportsCount,
    },
    {
      id: 'reportersCount',
      title: t('unsafe-content.columns.reporters-number'),
      maxWidth: 70,
      isSortable: true,
      renderCell: data => data.reportersCount,
    },
    {
      id: 'reporters',
      title: t('unsafe-content.columns.reporters'),
      maxWidth: 150,
      renderCell: data => (
        <Reporters
          reporters={data.reporters}
          onReporterClick={(type: string, id: number) => {
            showPopover(<UserDetailsCard userId={id} />);
          }}
          modalToggle={toggleModal}
        />
      ),
    },
    {
      id: 'status',
      title: t('unsafe-content.columns.status'),
      align: 'center',
      renderCell: data => (data.hidden ? 'hidden' : 'visible'),
    },
    {
      id: 'comments',
      minWidth: 150,
      title: t('unsafe-content.columns.comments'),
      renderCell: data => (
        <PointerContainer
          onClick={element => {
            setReportActionAnchor(element.currentTarget);
            setSelectedReport(data);
            setSelectedAction(ActionType.COMMENT);
          }}>
          {data.lastComment}
        </PointerContainer>
      ),
    },
    {
      id: 'actions',
      title: t('unsafe-content.columns.actions'),
      align: 'center',
      maxWidth: 80,
      renderCell: data => (
        <ReportActions
          isHidden={data.hidden}
          isDeleted={data.deleted}
          id={data.objectId}
          objectType={data.objectType}
          onPress={(element, action) => {
            setReportActionAnchor(element);
            setSelectedReport(data);
            setSelectedAction(action);
          }}
        />
      ),
    },
  ];

  const onChangeHandle = (name: string, checked: boolean) => {
    const switchedCheckbox = checkboxesState.filter(checkbox => checkbox.name === name)[0];
    if (switchedCheckbox) switchedCheckbox.checked = checked;

    if (!checked) {
      const secondCheckbox = checkboxesState.filter(checkbox => checkbox.name !== name)[0];
      secondCheckbox.checked = true;
      return [secondCheckbox];
    }
    return [];
  };

  const handleOnTableStateChanged = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const customFilters = convertFiltersToReportFilterDto(tableState);
    return reportService.fetchReport(customFilters).then(response => response.data);
  }, []);

  const handleOnCloseActionPopover = () => {
    setReportActionAnchor(undefined);
    setSelectedReport(undefined);
    setSelectedAction(undefined);
  };

  const handleRemainderClose = () => {
    setReminderAnchor(undefined);
    setReminderUserId(undefined);
  };

  const handleBlockPopupClose = () => {
    setBlockAnchor(undefined);
    setSelectedUser(undefined);
  };

  const handleBlockPopupOpen = () => {
    setBlockAnchor(reminderAnchor);
    userService.fetchPublicUser(reminderUserId!).then(({ data }) => setSelectedUser(data));
    handleRemainderClose();
  };

  const handleBlockUser = (userId: number, reason: string, comment?: string) => {
    userService.blockUser(userId, reason, comment).then(() => dataTableRef.current?.refresh());
    handleBlockPopupClose();
  };

  const handleOnActionPress = (action: ActionType, objectId: number, type: string, comment?: string) => {
    if (action === ActionType.COMMENT) {
      reportService
        .addComment(objectId, type, comment)
        .then(() => dataTableRef.current?.refresh())
        .catch(e => handleError(e, t('unsafe-content.error-dialog.comment-error')));
    } else if (action === ActionType.REMOVE) {
      reportService
        .clearUserData(objectId, type, comment)
        .then(() => {
          setReminderAnchor(reportActionAnchor);
          setReminderUserId(objectId);
          dataTableRef.current?.refresh();
        })
        .catch(e => handleError(e, t('unsafe-content.error-dialog.clear-error')));
    } else if (action === ActionType.IGNORE)
      reportService
        .ignoreReport(objectId, type, comment)
        .then(() => dataTableRef.current?.refresh())
        .catch(e => handleError(e, t('unsafe-content.error-dialog.ignore-error')));
    else
      reportService
        .hideObject(objectId, type, comment)
        .then(() => dataTableRef.current?.refresh())
        .catch(e => handleError(e, t('unsafe-content.error-dialog.hide-error')));

    handleOnCloseActionPopover();
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const filters: TableFilter[] = [
    {
      type: 'dropdown',
      name: 'objectType',
      label: t('unsafe-content.filters.object-type'),
      options: Object.values(ObjectType),
    },
    { type: 'date', name: 'dateFrom', label: t('unsafe-content.filters.date-from') },
    { type: 'date', name: 'dateTo', label: t('unsafe-content.filters.date-to') },
    {
      type: 'checkbox',
      name: 'visible',
      label: t('unsafe-content.filters.visible'),
      checked: true,
      onChange: onChangeHandle,
    },
    {
      type: 'checkbox',
      name: 'hidden',
      label: t('unsafe-content.filters.hidden'),
      checked: true,
      onChange: onChangeHandle,
    },
    { type: 'search', name: 'searchValue', label: t('common:search') },
  ];

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          onTableStateChanged={handleOnTableStateChanged}
          filters={filters}
        />
      </Container>
      {reportActionAnchor && selectedReport && selectedAction && (
        <ReportActionPopover
          anchor={reportActionAnchor}
          objectId={selectedReport.objectId}
          objectType={selectedReport.objectType}
          action={selectedAction}
          onActionPressed={comment =>
            handleOnActionPress(selectedAction, selectedReport.objectId, selectedReport.objectType, comment)
          }
          onClosePressed={handleOnCloseActionPopover}
        />
      )}
      {reminderAnchor && reminderUserId && (
        <UserBlockReminderPopover
          anchor={reminderAnchor}
          objectId={reminderUserId}
          onClosePressed={handleRemainderClose}
          onBlockPressed={handleBlockPopupOpen}
        />
      )}
      {selectedUser && blockAnchor && (
        <BlockUserPopover
          anchor={blockAnchor}
          userData={selectedUser}
          blockadeReasons={blockadeReasons}
          onBlockPressed={(reason, comment) => handleBlockUser(selectedUser.id, reason, comment)}
          onClosePressed={handleBlockPopupClose}
        />
      )}
    </PageContainer>
  );
};
export default UnsafeContentReports;
