import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { Column } from '../../../components/table/DataTable';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { TableFilter } from '../../../components/table/FiltersPanel';
import DataTableWithFilters from '../../../components/table/DataTableWithFilters';
import CellWithPopover from '../../../components/table/CellWithPopover';
import { ITEM_STATUSES, ITEM_TYPES, ItemInfoDto, SELLING_METHODS } from '../../../services/Item/itemService.dto';
import CellWithEdit from '../../../components/table/CellWithEdit';
import { dateUtils } from '../../../utils/dateUtils';
import { useUpdateSortingBoost } from '../../../components/sortingBoost/useUpdateSortingBoost';
import { useItemFilters } from '../hooks/useItemFilters';
import CampaignDetailsCard from '../../campaigns/components/CampaignDetailsCard';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import { usePopup } from '../../../providers/PopupProvider';
import FloatingButton from '../../../components/buttons/FloatingButton';
import { theme } from '../../../assets/styles/theme';
import ItemDetailsCard from './ItemDetailsCard';
import AddItemForm from './form/ItemForm';

const EditButton = styled(EditIcon)`
  cursor: pointer;
`;

const ItemsTable = () => {
  const { t } = useTranslation('items');
  const dataTableRef = useRef<DataTableRef>(null);
  const { showPopover, hidePopover } = usePopup();
  const { onTableFiltered, filterInitState } = useItemFilters();
  const { handleItemSortingBoostUpdate } = useUpdateSortingBoost(() => dataTableRef.current?.refresh());

  const columns: Column<ItemInfoDto>[] = [
    {
      id: 'id',
      title: t('items.columns.id'),
      isSortable: true,
      maxWidth: 50,
      align: 'center',
      renderCell: data => (
        <CellWithPopover popover={<ItemDetailsCard itemId={data.id} itemInfo={data} />}>{data.id}</CellWithPopover>
      ),
    },
    {
      id: 'created',
      title: t('items.columns.created'),
      isSortable: true,
      maxWidth: 100,
      renderCell: data => dateUtils.formatDateDate(new Date(data.created)),
    },
    {
      id: 'title',
      title: t('items.columns.title'),
      isSortable: true,
      renderCell: data => data.title,
    },
    {
      id: 'price',
      title: t('items.columns.price'),
      maxWidth: 80,
      isSortable: true,
      renderCell: data => data.price,
    },
    {
      id: 'currency',
      title: t('items.columns.currency'),
      maxWidth: 60,
      isSortable: true,
      renderCell: data => data.currency,
    },
    {
      id: 'itemType',
      title: t('items.columns.type'),
      maxWidth: 80,
      isSortable: true,
      renderCell: data => data.itemType,
    },
    {
      id: 'status',
      title: t('items.columns.status'),
      maxWidth: 80,
      isSortable: true,
      renderCell: data => data.itemStatus,
    },
    {
      id: 'sellingMethod',
      title: t('items.columns.selling-method'),
      maxWidth: 80,
      isSortable: true,
      renderCell: data => data.sellingMethod,
    },
    {
      id: 'creatorId',
      title: t('items.columns.owner-id'),
      maxWidth: 70,
      isSortable: true,
      align: 'center',
      renderCell: data => (
        <CellWithPopover popover={<UserDetailsCard userId={data.ownerId} />}>{data.ownerId}</CellWithPopover>
      ),
    },
    {
      id: 'campaignId',
      title: t('items.columns.campaign-id'),
      maxWidth: 70,
      isSortable: true,
      align: 'center',
      renderCell: data => (
        <CellWithPopover popover={<CampaignDetailsCard campaignId={data.supportedCampaignId} />}>
          {data.supportedCampaignId}
        </CellWithPopover>
      ),
    },
    {
      id: 'adminSortingBoost',
      title: t('items.columns.sorting-boost'),
      isSortable: true,
      maxWidth: 70,
      align: 'center',
      renderCell: data => (
        <CellWithEdit onClick={element => handleItemSortingBoostUpdate(element, data)}>
          {data.adminSortingBoost}
        </CellWithEdit>
      ),
    },
    {
      id: 'actions',
      title: t('items.columns.actions'),
      align: 'center',
      maxWidth: 50,
      renderCell: data =>
        data.itemStatus === 'PUBLISHED' ? (
          <EditButton onClick={() => showPopover(<AddItemForm itemId={data.id} onItemSaved={onItemSaved} />)} />
        ) : (
          ''
        ),
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: t('items.filters.id') },
    { type: 'text', name: 'title', label: t('items.filters.title') },
    { type: 'numeric', name: 'ownerId', label: t('items.filters.owner-id') },
    { type: 'numeric', name: 'campaignId', label: t('items.filters.campaign-id') },
    {
      type: 'multi-choice',
      name: 'type',
      options: [...ITEM_TYPES],
      label: t('items.filters.type'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...ITEM_STATUSES],
      label: t('items.filters.status'),
    },
    {
      type: 'multi-choice',
      name: 'sellingMethod',
      options: [...SELLING_METHODS],
      label: t('items.filters.selling-method'),
    },
  ];

  const onItemSaved = () => {
    hidePopover();
    dataTableRef.current?.refresh();
  };

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        filterInitState={filterInitState}
        onTableStateChanged={onTableFiltered}
      />
      <FloatingButton onClick={() => showPopover(<AddItemForm onItemSaved={onItemSaved} />)}>
        <AddIcon style={{ color: theme.color.white, fontSize: 22 }} />
      </FloatingButton>
    </>
  );
};

export default ItemsTable;
