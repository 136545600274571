import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessages } from '../../../../providers/messages/MessagesProvider';
import ConversationList from '../ConversationList';
import { useSearchMessages } from '../../../../providers/messages/useSearchMessages';
import { ConversationStatus } from '../../../../services/Message/messageService.dto';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import SearchConversationList from '../SearchConversationList';
import { SearchType } from '../searchType';

interface Props {
  path: string;
  selectedConversationId?: number;
  onCloseConversation: () => void;
  searchValue: string;
  searchType: SearchType;
}

const ConversationTabOngoing = (props: Props) => {
  const { searchValue, searchType } = props;
  const { t } = useTranslation('messages');
  const { ongoingConversations } = useMessages();

  const searchOngoing = useSearchMessages(ConversationStatus.ONGOING);

  useEffect(() => {
    ongoingConversations.fetchConversations();
    return () => ongoingConversations.resetConversations();
  }, []);

  useEffect(() => {
    searchOngoing.fetchMessagesBySearch(searchValue, searchType);
    if (searchValue) props.onCloseConversation();
  }, [searchValue, searchType]);

  const renderConversationsList = () => {
    if (searchValue) {
      if (!searchOngoing.searchResult || searchOngoing.isLoading) return <LoadingIndicator />;
      return (
        <SearchConversationList
          path={props.path}
          conversations={searchOngoing.searchResult}
          selectedConversationId={props.selectedConversationId}
        />
      );
    } else {
      return (
        <ConversationList
          title={t('header-ongoing')}
          path={props.path}
          selectedConversationId={props.selectedConversationId}
          {...ongoingConversations}
        />
      );
    }
  };

  return renderConversationsList();
};

export default ConversationTabOngoing;
