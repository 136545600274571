import React, { CSSProperties, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import styled from 'styled-components';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StyleIcon from '@mui/icons-material/Style';
import { useTranslation } from 'react-i18next';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { theme } from '../../assets/styles/theme';
import PageableType from '../../services/utils/pageableType';
import LoadingIndicator from '../loading/LoadingIndicator';
import Pagination from './Pagination';
import { useDataTableReducer } from './dataTable.reducer';
import { DataTableRef } from './dataTableRef';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledRow = styled(TableRow)`
  :nth-child(even) {
    background-color: ${theme.color.lighterGray};
  }
`;

const SortArrowContainer = styled.span`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  text-align: center;
`;

const StyledCell = styled.td`
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.xs};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  padding: 5px;
  border: 1px solid ${theme.color.lightGray};
  word-wrap: break-word;
`;

const StyledHeader = styled(StyledCell)<{ isSortable?: boolean }>`
  color: ${theme.color.white};
  background-color: ${theme.color.red};
  font-size: ${theme.font.size.s};
  position: sticky;
  top: 0;
  border-width: 0;
  border-right-width: 1px;
  padding-right: ${props => (props.isSortable ? '20px' : 'auto')};
  cursor: ${props => (props.isSortable ? 'pointer' : 'default')};
  z-index: 1;

  :hover {
    text-decoration: ${props => (props.isSortable ? 'underline' : 'none')};
  }
`;

const NoDataContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  line-height: 26px;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export interface Column<T> {
  id: string;
  title: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right' | 'center';
  isSortable?: boolean;
  renderCell: (data: T) => JSX.Element | string | number | Date | undefined;
}

export interface TableFilterStateDto<FilterType> {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  filtersMap?: FilterType;
}

interface Props<DataType, FilterType> {
  columns: Column<DataType>[];
  filterData?: FilterType;
  styleRow?: (data: DataType) => CSSProperties;
  onTableStateChanged: (tableState: TableFilterStateDto<FilterType>) => Promise<PageableType<DataType>>;
  ref?: Ref<DataTableRef>;
  skipInitialFetch?: boolean;
  disablePagination?: boolean;
}

const DataTable = <DataType, FilterType>(props: Props<DataType, FilterType>, ref: Ref<DataTableRef>) => {
  const { columns, filterData, onTableStateChanged, styleRow, skipInitialFetch, disablePagination } = props;
  const { handleError } = useGlobalError();
  const { t } = useTranslation();

  const { tableState, onSortChanged, onPageChanged, onFilterChanged, onFetchRequest, onFetchSuccess } =
    useDataTableReducer<DataType, FilterType>();

  useImperativeHandle(ref, () => ({ refresh: () => fetchData() }));

  const filterDto: TableFilterStateDto<FilterType> = useMemo(() => {
    return {
      page: tableState.page,
      pageSize: tableState.rowsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filtersMap: tableState.filterData,
    };
  }, [tableState.page, tableState.rowsPerPage, tableState.sortBy, tableState.sortDirection, tableState.filterData]);

  useEffect(() => {
    if (filterData) onFilterChanged(filterData);
  }, [filterData, onFilterChanged]);

  const fetchData = useCallback(() => {
    if (!!skipInitialFetch && !filterDto.filtersMap) {
      return;
    }

    onFetchRequest();
    onTableStateChanged(filterDto)
      .then(response => onFetchSuccess(response))
      .catch(e => handleError(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchRequest, onFetchSuccess, onTableStateChanged, filterDto]);

  useEffect(() => fetchData(), [fetchData]);

  const hasNoData = !tableState.isLoading && tableState.totalNumberOfElements === 0;
  const isPaginationVisible = !disablePagination && !tableState.isLoading;

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledHeader
                  key={column.id}
                  align={column.align}
                  isSortable={column.isSortable}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, textAlign: column.align }}
                  onClick={() => column.isSortable && onSortChanged(column.id)}>
                  {column.title}
                  <SortArrowContainer>
                    {tableState.sortBy === column.id && tableState.sortDirection === 'asc' && (
                      <ArrowUpwardIcon style={{ fontSize: '18px' }} />
                    )}
                    {tableState.sortBy === column.id && tableState.sortDirection === 'desc' && (
                      <ArrowDownwardIcon style={{ fontSize: '18px' }} />
                    )}
                  </SortArrowContainer>
                </StyledHeader>
              ))}
            </TableRow>
          </TableHead>
          {!tableState.isLoading && (
            <TableBody>
              {tableState.rows.map((row, index) => (
                <StyledRow style={!!styleRow ? styleRow(row) : {}} key={index}>
                  {columns.map(column => (
                    <StyledCell key={column.id} style={{ maxWidth: column.maxWidth, textAlign: column.align }}>
                      <>{column.renderCell(row)}</>
                    </StyledCell>
                  ))}
                </StyledRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {tableState.isLoading && <LoadingIndicator />}
      {hasNoData && (
        <NoDataContainer>
          <StyleIcon style={{ fontSize: 40 }} />
          {t('no-records')}
        </NoDataContainer>
      )}
      {isPaginationVisible && (
        <Pagination
          totalNumberOfRows={tableState.totalNumberOfElements}
          page={tableState.page}
          rowsPerPage={tableState.rowsPerPage}
          onPageChanged={onPageChanged}
        />
      )}
    </Container>
  );
};

export default forwardRef(DataTable) as typeof DataTable;
