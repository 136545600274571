import React from 'react';
import styled from 'styled-components';
import noMessagesImg from '../../../assets/images/no-messages.png';
import { SectionHeader } from '../../../components/typography/Headers';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConversationEmptyList = () => {
  return (
    <Container>
      <img src={noMessagesImg} alt={'no messages'} width={'150px'} />
      <SectionHeader>No messages!</SectionHeader>
    </Container>
  );
};

export default ConversationEmptyList;
